@use "../node_modules/sass-rem" as rem;
@use "../node_modules/sass-em" as em;
.social {
  display: block;
  padding-bottom: rem.convert(40);

  @include bp(min, $bp-3) {
    display: inline-block;
    float: right;
  }
}

.social a {
  font-family: $font-stack;
  font-size: rem.convert(14);
}

.social a::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  margin-right: em.convert(10px, 16px);
  background: url('./../assets/images/icons/facebook.svg');
}
