@use "../node_modules/sass-rem" as rem;
.section-seo-text p {
  font-size: rem.convert(12);
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: shade($color-secondary, 20%);

  strong {
    font-weight: bold;
  }
}
