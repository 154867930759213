@use "../node_modules/sass-rem" as rem;
@use "../node_modules/sass-em" as em;
.section-quote {
  h1 {
    margin-bottom: rem.convert(60px);
  }
}

.section-quote__inner {
  @include unit(margin-left, 1);
  display: inline-block;

  blockquote {
    margin-bottom: em(30);

    @include bp(min, $bp-3) {
      @include unit(width, 6);
      @include unit(margin-left, .5);
      float: left;
      padding-left: em.convert(20px, 16px);
      border-left: 3px solid tint($color-secondary, 40%);
    }
  }
  blockquote p {
    @include type-ml-to-l;
    margin-bottom: rem.convert(10px);
    font-weight: 200;
    color: shade($color-secondary, 20%);
  }
  blockquote cite {
    @include type-s-to-m;
    font-family: $font-stack;
    font-weight: 400;
    color: shade($color-secondary, 40%);
    line-height: 1.4;
  }
}

.section-quote__inner__image {
  display: block;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: rem.convert(40px);
  img {
    border-radius: 50%;
  }
  @include bp(min, $bp-3) {
    @include unit(width, 2);
    float: left;
    max-width: 280px;
    display: inline-block;
  }
}
