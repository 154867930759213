.header-curve {
  transform: translateX(-5%);
  position: relative;
  width: 110%;
  margin-top: -5px;

  svg {
    width: 100%;
  }
}
