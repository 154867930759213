@use "../node_modules/sass-rem" as rem;
@use "../node_modules/sass-em" as em;
.video-container {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  max-width: 100%;
  background: rgba(14,132,160,0.9);
  z-index: 9999;
  display: none;
}

.video-container.is-visible {
  display: block;
}

.no-scroll {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.video-container__close {
  position: absolute;
  right: em.convert(20px, 16px);
  top: em.convert(20px, 16px);
  font-family: $font-stack;
  color: #fff !important;
  z-index: 9999;
}

.video-container__embed iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-container__embed {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding-bottom: 56.25%;
  padding-top: 0px;
  height: 0;
  overflow: hidden;
}

.video-container__inner {
  width: 100%;
  max-width: 1400px;
  height: 100%;
  margin-left: 50%;
  padding: em.convert(20px, 16px);
  transform: translateX(-50%);

  @include bp(min, $bp-3) {
    padding: em.convert(100px, 16px);
  }
}
