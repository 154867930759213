a.button,
.button {
  //@include transition(all 0.2s ease-in-out);

  &:hover {
    background: #fff;
    color: $color-button-bg;
  }
}

a.button--secondary,
.button--secondary {
  background: $color-button-secondary-bg;
  color: $color-button-secondary-text;
  border: 2px solid $color-button-secondary-border;

  &:hover {
    background: $color-button-secondary-hover;
    color: #fff;
  }
}

a.button--small:hover,
.button--small:hover {
  background: transparent;
  color: $color-button-text;
  border-color: #fff;
}

section.book-button.mobile {
  text-align: center;
  @media screen and (min-width:800px) {
    display: none;
  }
}

a.button--primary__signal {
  display: inline-block;
  margin: 0 auto;
  background: $color-signal;
  color: darken($color-signal, 40%);
  border-radius: 3px;
  border: 2px solid $color-signal;
    &:hover {
    background: $color-signal;
    color: darken($color-signal, 40%);
    border: 2px solid $color-signal;
  }
}