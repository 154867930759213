@use "../node_modules/sass-rem" as rem;
@use "../node_modules/sass-em" as em;
.nav {
  transition: transform 0.33s cubic-bezier(0.33, 0, 0.2, 1);

  @include bp(min, 1370px) {
    @include unit(right);
  }
}

.nav__list a {
  transition: all 0.2s ease-in-out;
}

.nav__list li {
  &.nav__list__item {
    @include bp(min, 1370px) {
      display: flex!important;
    }
    transition: all 0.2s ease-in-out;
    &:hover {
      background: #F6F6F6;
      a {
        color: #0E84A0;
      }
    }
  }
}

a.nav-toggle {
  float: right;
  font-family: $font-stack;
  color: #fff;
  text-decoration: none;
  line-height: 1.4;
}

.nav__list__sub {
  display: inline-block !important;
  margin-bottom: em.convert(10px, 16px) !important;
  padding: 0;

  @include bp(min, $bp-3) {
    display: none !important;
    position: absolute;
    left: 0;
    width: 270px !important;
    margin-left: 0 !important;
    padding: em.convert(10px, 16px) em.convert(18px, 16px);
    background: #fff;
    box-shadow: rgba(0,0,0,0.2) 0 0 4px;
  }
}

.nav__list__sub li {
  display: block;
}

.nav__list__sub a {
  @include type-s-to-m;

  @include bp(min, $bp-3) {
    padding-top: em.convert(4px, 16px);
    padding-bottom: em.convert(4px, 16px);
    color: $color-primary;
  }
}

.nav__list li:hover ul {
  display: block !important;
}

.js-submenu-btn.active {
  background: #F6F6F6;

  > a {
    color: #0E84A0;
  }
}

.nav__list li.nav__list__mobilemenu {
  @include bp(min, 1370px) {
    display: none;
  }

  > a {
    &:hover {
      background: #F6F6F6;
      color: #0E84A0;
    }
  }
}

.nav-mobile {
  @include bp(min, 1370px) {
    display: none!important;
  }

  @include bp(min-max, 800px, 1369px) {
    padding-left: 4.33333vw;
    padding-right: 4.33333vw;
    height: 80px;
    line-height: 80px;
  }

  position: absolute;
  width: 100%;
  background: #F6F6F6;
  top: 80px;
  left: 0;
  border-bottom: 1px solid #D8D8D8;
  transform: translateX(0%);
  transition: transform 0.33s cubic-bezier(0.33, 0, 0.2, 1);

  &.slided {
    transform: translateX(-100%);
  }
}

.nav__list--mobile {
  display: flex;
  @include bp(max, 799px) {
    flex-direction: column;
  }
}

.nav__list--mobile li {
  font-family: $font-stack;

  &:not(:last-child) {
    margin-right: rem.convert(30px);
  }

  @include bp(max, 799px) {
    padding-left: 4.33333vw;
    padding-right: 4.33333vw;
    padding-top: rem.convert(20px);
    padding-bottom: rem.convert(20px);
    display: flex;
    justify-content: space-between;

    &:not(:last-child) {
      border-bottom: 1px solid #D8D8D8;
      margin-right: 0;
    }

    &::after {
      content: '→';
      color: #AAA792;
      margin-right: rem.convert(6px);
      display: inline;
    }
  }
}

.js-submenu-btn--mobile.active {
  @include bp(min-max, 800px, 1369px) {
    &::after {
      content: "";
      width: 0;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #d8d8d8;
    }
  }
}

.js-submenu-btn--mobile, .js-submenu-btn--mobile a {
  position: relative;
}

.js-submenu-btn--mobile.active a {
  @include bp(min-max, 800px, 1369px) {
    &::after {
      content: '';
      display: block;
      position: absolute;
      background: #0E84A0;
      width: 138px;
      height: 2px;
      top: 16px;
      left: 0;
    }
  }
}
