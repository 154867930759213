@use "../node_modules/sass-rem" as rem;
@use "../node_modules/sass-em" as em;
.section-profil {
  width: 100%;

  @include bp(min, $bp-3) {
    @include unit(padding-top, 0.5);
    width: 100%;
  }

  @include bp(min, $bp-5) {
    @include unit(max-width, 10);
    @include unit(margin-left, 1);
  }
}

.section-profil__item {
  position: relative;
  margin-bottom: em.convert(60px, 16px);
  padding-left: em.convert(40px, 16px);
  padding-right: em.convert(40px, 16px);
  text-align: left;
}

.section-profil__item__inner {
  position: absolute;
  left: em.convert(20px, 16px);
  bottom: -(em.convert(10px, 16px));
  padding-left: em.convert(10px, 16px);
  padding-right: em.convert(10px, 16px);
  background: #fff;
}

.section-profil h2 {
  @include type-m-to-ml;
}

.team-label {
  @include type-s-to-m;
  margin-bottom: 0;
  padding-top: em.convert(4px, 16px);
  color: shade($color-secondary, 20%);
}
