@use "../node_modules/sass-rem" as rem;
@use "../node_modules/sass-em" as em;
.next-read {
  @include unit(max-width, 10);
  @include unit(margin-bottom, 1.5);
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding: em.convert(40px, 16px);
  background: $color-primary;

  @include bp(min, $bp-4) {
    @include unit(max-width, 6);
    @include unit(margin-left, 5);
    @include unit(margin-bottom, 1);
  }
}

.next-read h3 {
  @include type-l-to-xl;
  margin-left: 0;
  color: #fff;

  @include bp(max, $bp-2) {
    font-size: em.convert(20px, 16px);
  }
}

.next-read p {
  margin-bottom: em.convert(10px, 16px) !important;
  color: #fff;
}

.next-read .button {
  position: absolute;
  right: em.convert(40px, 16px);
  bottom: -(em.convert(20px, 16px));
  background: #fff;
  color: $color-primary;

  &:hover {
    background: $color-primary;
    color: #fff;
    border-color: #fff;
  }
}
