@charset "UTF-8";
/* --- Main Colors --- */
/* --- Link --- */
/* --- Button --- */
/* --- Stage --- */
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, acronym, address, big, cite, code,
del, dfn, em, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, hgroup,
menu, output, ruby, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, hgroup, menu {
  display: block;
}

ol {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

.type-s {
  font-size: 0.75rem;
  line-height: 1.4;
}

.type-m {
  font-size: 1rem;
  line-height: 1.5;
}

.type-ml {
  font-size: 1.25rem;
  line-height: 1.5;
}

.type-l {
  font-size: 1.875rem;
  line-height: 1.4;
}

.type-xl {
  font-size: 2.8125rem;
  line-height: 1.2;
}

.type-xxl {
  font-size: 3.125rem;
  line-height: 1.2;
}

.type-xs-to-s {
  font-size: 0.625rem;
}
@media screen and (min-width: 48em) {
  .type-xs-to-s {
    font-size: 0.75rem;
    line-height: 1.4;
  }
}

.type-s-to-ml {
  font-size: 0.75rem;
  line-height: 1.4;
}
@media screen and (min-width: 48em) {
  .type-s-to-ml {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}

.type-m-to-ml {
  font-size: 1rem;
  line-height: 1.5;
}
@media screen and (min-width: 80em) {
  .type-m-to-ml {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}

.type-m-to-l {
  font-size: 1rem;
  line-height: 1.5;
}
@media screen and (min-width: 36em) {
  .type-m-to-l {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}
@media screen and (min-width: 48em) {
  .type-m-to-l {
    font-size: 1.875rem;
    line-height: 1.4;
  }
}

.type-s-to-m {
  font-size: 0.75rem;
  line-height: 1.4;
}
@media screen and (min-width: 48em) {
  .type-s-to-m {
    font-size: 1rem;
    line-height: 1.5;
  }
}

.type-l-to-xl {
  font-size: 1.875rem;
  line-height: 1.4;
}
@media screen and (min-width: 48em) {
  .type-l-to-xl {
    font-size: 2.8125rem;
    line-height: 1.2;
  }
}

.type-l-to-xxl {
  font-size: 1.875rem;
  line-height: 1.4;
}
@media screen and (min-width: 48em) {
  .type-l-to-xxl {
    font-size: 2.8125rem;
    line-height: 1.2;
  }
}
@media screen and (min-width: 80em) {
  .type-l-to-xxl {
    font-size: 3.125rem;
    line-height: 1.2;
  }
}

.type-l-to-xxxl {
  font-size: 1.875rem;
  line-height: 1.4;
}
@media screen and (min-width: 48em) {
  .type-l-to-xxxl {
    font-size: 3.125rem;
    line-height: 1.2;
  }
}
@media screen and (min-width: 63.9375em) {
  .type-l-to-xxxl {
    font-size: 5rem;
  }
}

.type-ml-to-xl {
  font-size: 1.25rem;
  line-height: 1.5;
}
@media screen and (min-width: 48em) {
  .type-ml-to-xl {
    font-size: 1.875rem;
    line-height: 1.4;
  }
}

.type-ml-to-l {
  font-size: 1.25rem;
  line-height: 1.5;
}
@media screen and (min-width: 48em) {
  .type-ml-to-l {
    font-size: 1.875rem;
    line-height: 1.4;
  }
}

.text-link {
  color: blue;
}
.text-link:hover {
  text-decoration: underline;
}

a:hover {
  color: #074250;
}

h1, h2, h3, h4 {
  font-family: "RealHead", Helvetica, Arial, sans-serif;
  font-weight: 800;
  line-height: 1.2;
  color: #0E84A0;
}

p {
  margin-bottom: 1.25em;
  font-family: "RealText", Helvetica, Arial, sans-serif;
  line-height: 1.45;
}

a.anchor {
  display: none;
}

p.small {
  font-size: 0.75rem;
  line-height: 1.4;
  color: #fff;
  text-align: center;
}
@media screen and (min-width: 48em) {
  p.small {
    font-size: 1rem;
    line-height: 1.5;
  }
}

.page.menu-opened {
  overflow: initial;
}

.three-up {
  margin-left: -4.1666666667vw;
  margin-right: -4.1666666667vw;
  text-align: center;
  overflow: hidden;
}
@media screen and (min-width: 1920) {
  .three-up {
    margin-left: -80px;
  }
}
@media screen and (min-width: 1920) {
  .three-up {
    margin-right: -80px;
  }
}
.three-up .cell {
  float: left;
  width: 100%;
  padding-right: 4.1666666667vw;
  padding-left: 4.1666666667vw;
}
@media screen and (min-width: 1920) {
  .three-up .cell {
    padding-right: 80px;
  }
}
@media screen and (min-width: 1920) {
  .three-up .cell {
    padding-left: 80px;
  }
}
@media screen and (min-width: 26.25em) {
  .three-up .cell {
    width: 50%;
  }
  .three-up .cell:nth-child(2n+1) {
    clear: left;
  }
}
@media screen and (min-width: 63.9375em) {
  .three-up .cell {
    width: 33.3333333333%;
  }
  .three-up .cell:nth-child(2n+1) {
    clear: none;
  }
  .three-up .cell:nth-child(3n+1) {
    clear: left;
  }
}

.four-up {
  margin-left: -4.1666666667vw;
  margin-right: -4.1666666667vw;
  text-align: center;
}
@media screen and (min-width: 1920) {
  .four-up {
    margin-left: -80px;
  }
}
@media screen and (min-width: 1920) {
  .four-up {
    margin-right: -80px;
  }
}
.four-up .cell {
  margin-bottom: 8.3333333333vw;
  padding-right: 4.1666666667vw;
  padding-left: 4.1666666667vw;
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
@media screen and (min-width: 1920) {
  .four-up .cell {
    margin-bottom: 160px;
  }
}
@media screen and (min-width: 1920) {
  .four-up .cell {
    padding-right: 80px;
  }
}
@media screen and (min-width: 1920) {
  .four-up .cell {
    padding-left: 80px;
  }
}
@media screen and (min-width: 63.9375em) {
  .four-up .cell {
    width: 25%;
  }
}

.five-up {
  margin-left: -4.1666666667vw;
  margin-right: -4.1666666667vw;
  text-align: center;
}
@media screen and (min-width: 1920) {
  .five-up {
    margin-left: -80px;
  }
}
@media screen and (min-width: 1920) {
  .five-up {
    margin-right: -80px;
  }
}
@media screen and (min-width: 63.9375em) {
  .five-up {
    max-width: 1200px;
    margin: 0 auto !important;
  }
}
.five-up .cell {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  padding: 4.1666666667vw;
}
@media screen and (min-width: 1920) {
  .five-up .cell {
    padding: 80px;
  }
}
@media screen and (min-width: 36em) {
  .five-up .cell {
    padding: 2.0833333333vw;
    width: 33%;
  }
}
@media screen and (min-width: 36em) and (min-width: 1920) {
  .five-up .cell {
    padding: 40px;
  }
}
@media screen and (min-width: 63.9375em) {
  .five-up .cell {
    padding: 1.25vw;
    width: 20%;
  }
}
@media screen and (min-width: 63.9375em) and (min-width: 1920) {
  .five-up .cell {
    padding: 24px;
  }
}

*.hidden {
  display: none;
}

*.cursor-pointer {
  cursor: pointer;
}

*.color-primary {
  color: #0E84A0;
}

.no-bottom-padding {
  padding-bottom: 0 !important;
}

.hide {
  display: none;
}

.response {
  font-size: 1rem;
  line-height: 1.5;
  font-family: "RealText", Helvetica, Arial, sans-serif;
  font-weight: 300;
}
@media screen and (min-width: 80em) {
  .response {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}

.nav {
  transition: transform 0.33s cubic-bezier(0.33, 0, 0.2, 1);
}
@media screen and (min-width: 85.625em) {
  .nav {
    right: 8.3333333333vw;
  }
}
@media screen and (min-width: 85.625em) and (min-width: 1920) {
  .nav {
    right: 160px;
  }
}

.nav__list a {
  transition: all 0.2s ease-in-out;
}

.nav__list li.nav__list__item {
  transition: all 0.2s ease-in-out;
}
@media screen and (min-width: 85.625em) {
  .nav__list li.nav__list__item {
    display: flex !important;
  }
}
.nav__list li.nav__list__item:hover {
  background: #F6F6F6;
}
.nav__list li.nav__list__item:hover a {
  color: #0E84A0;
}

a.nav-toggle {
  float: right;
  font-family: "RealText", Helvetica, Arial, sans-serif;
  color: #fff;
  text-decoration: none;
  line-height: 1.4;
}

.nav__list__sub {
  display: inline-block !important;
  margin-bottom: 0.625em !important;
  padding: 0;
}
@media screen and (min-width: 48em) {
  .nav__list__sub {
    display: none !important;
    position: absolute;
    left: 0;
    width: 270px !important;
    margin-left: 0 !important;
    padding: 0.625em 1.125em;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 4px;
  }
}

.nav__list__sub li {
  display: block;
}

.nav__list__sub a {
  font-size: 0.75rem;
  line-height: 1.4;
}
@media screen and (min-width: 48em) {
  .nav__list__sub a {
    font-size: 1rem;
    line-height: 1.5;
  }
}
@media screen and (min-width: 48em) {
  .nav__list__sub a {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    color: #0E84A0;
  }
}

.nav__list li:hover ul {
  display: block !important;
}

.js-submenu-btn.active {
  background: #F6F6F6;
}
.js-submenu-btn.active > a {
  color: #0E84A0;
}

@media screen and (min-width: 85.625em) {
  .nav__list li.nav__list__mobilemenu {
    display: none;
  }
}
.nav__list li.nav__list__mobilemenu > a:hover {
  background: #F6F6F6;
  color: #0E84A0;
}

.nav-mobile {
  position: absolute;
  width: 100%;
  background: #F6F6F6;
  top: 80px;
  left: 0;
  border-bottom: 1px solid #D8D8D8;
  transform: translateX(0%);
  transition: transform 0.33s cubic-bezier(0.33, 0, 0.2, 1);
}
@media screen and (min-width: 85.625em) {
  .nav-mobile {
    display: none !important;
  }
}
@media screen and (min-width: 50em) and (max-width: 85.5625em) {
  .nav-mobile {
    padding-left: 4.33333vw;
    padding-right: 4.33333vw;
    height: 80px;
    line-height: 80px;
  }
}
.nav-mobile.slided {
  transform: translateX(-100%);
}

.nav__list--mobile {
  display: flex;
}
@media screen and (max-width: 49.9375em) {
  .nav__list--mobile {
    flex-direction: column;
  }
}

.nav__list--mobile li {
  font-family: "RealText", Helvetica, Arial, sans-serif;
}
.nav__list--mobile li:not(:last-child) {
  margin-right: 1.875rem;
}
@media screen and (max-width: 49.9375em) {
  .nav__list--mobile li {
    padding-left: 4.33333vw;
    padding-right: 4.33333vw;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    display: flex;
    justify-content: space-between;
  }
  .nav__list--mobile li:not(:last-child) {
    border-bottom: 1px solid #D8D8D8;
    margin-right: 0;
  }
  .nav__list--mobile li::after {
    content: "→";
    color: #AAA792;
    margin-right: 0.375rem;
    display: inline;
  }
}

@media screen and (min-width: 50em) and (max-width: 85.5625em) {
  .js-submenu-btn--mobile.active::after {
    content: "";
    width: 0;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #d8d8d8;
  }
}

.js-submenu-btn--mobile, .js-submenu-btn--mobile a {
  position: relative;
}

@media screen and (min-width: 50em) and (max-width: 85.5625em) {
  .js-submenu-btn--mobile.active a::after {
    content: "";
    display: block;
    position: absolute;
    background: #0E84A0;
    width: 138px;
    height: 2px;
    top: 16px;
    left: 0;
  }
}

.subnav {
  max-width: 83.3333333333vw;
  margin-top: 4.1666666667vw;
  margin-bottom: 4.1666666667vw;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 1920) {
  .subnav {
    max-width: 1600px;
  }
}
@media screen and (min-width: 1920) {
  .subnav {
    margin-top: 80px;
  }
}
@media screen and (min-width: 1920) {
  .subnav {
    margin-bottom: 80px;
  }
}
@media screen and (min-width: 48em) {
  .subnav {
    margin-top: 2.0833333333vw;
  }
}
@media screen and (min-width: 48em) and (min-width: 1920) {
  .subnav {
    margin-top: 40px;
  }
}

.subnav__list {
  width: 100%;
}

.subnav__list li {
  display: inline-block;
  margin-right: em(15);
  margin-bottom: em(10);
}

.subnav__list li:after {
  content: "→";
  margin-left: em(15);
  font-family: "RealText", Helvetica, Arial, sans-serif;
  color: #D4D1B6;
}

.subnav__list li:last-child:after {
  content: none;
}

.subnav__list a {
  font-size: 0.75rem;
  line-height: 1.4;
  font-family: "RealText", Helvetica, Arial, sans-serif;
}
@media screen and (min-width: 48em) {
  .subnav__list a {
    font-size: 1rem;
    line-height: 1.5;
  }
}

.subnav__list a.active {
  color: #aaa792;
}

.subnav--main .subnav__list li:after {
  content: "|";
}
.subnav--main .subnav__list li:last-child:after {
  content: none;
}

@media screen and (min-width: 85.625em) {
  .header {
    padding-left: 8.3333333333vw;
    padding-right: 8.3333333333vw;
    height: 120px;
  }
}
@media screen and (min-width: 85.625em) and (min-width: 1920) {
  .header {
    padding-left: 160px;
  }
}
@media screen and (min-width: 85.625em) and (min-width: 1920) {
  .header {
    padding-right: 160px;
  }
}

.header--home {
  transition: background-color 0.3s ease-in-out;
}
.header--home:hover {
  background-color: #0E84A0;
}

.header__submenu {
  position: absolute;
  top: 160px;
  left: 0;
  background: #F6F6F6;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
}
@media screen and (min-width: 85.625em) {
  .header__submenu {
    padding-left: 8.3333333333vw;
    padding-right: 8.3333333333vw;
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
    top: 120px;
  }
}
@media screen and (min-width: 85.625em) and (min-width: 1920) {
  .header__submenu {
    padding-left: 160px;
  }
}
@media screen and (min-width: 85.625em) and (min-width: 1920) {
  .header__submenu {
    padding-right: 160px;
  }
}
@media screen and (min-width: 50em) and (max-width: 85.5625em) {
  .header__submenu {
    padding-left: 4.33333vw;
    padding-right: 4.33333vw;
    height: 80px;
    line-height: 80px;
  }
}
@media screen and (max-width: 49.9375em) {
  .header__submenu {
    display: block !important;
    top: 80px;
    transition: transform 0.33s cubic-bezier(0.33, 0, 0.2, 1);
  }
  .header__submenu.slided {
    transform: translateX(0%);
  }
}
.header__submenu__arrow {
  cursor: pointer;
  position: absolute;
  top: 0;
  display: none;
  width: 100px;
  height: 100%;
  text-align: right;
  color: #0e84a0;
  font-family: "RealText", Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: bold;
  background: rgba(245, 245, 245, 0);
  background: -moz-linear-gradient(left, rgba(245, 245, 245, 0) 0%, rgb(245, 245, 245) 67%, rgb(245, 245, 245) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(245, 245, 245, 0)), color-stop(67%, rgb(245, 245, 245)), color-stop(100%, rgb(245, 245, 245)));
  background: -webkit-linear-gradient(left, rgba(245, 245, 245, 0) 0%, rgb(245, 245, 245) 67%, rgb(245, 245, 245) 100%);
  background: -o-linear-gradient(left, rgba(245, 245, 245, 0) 0%, rgb(245, 245, 245) 67%, rgb(245, 245, 245) 100%);
  background: -ms-linear-gradient(left, rgba(245, 245, 245, 0) 0%, rgb(245, 245, 245) 67%, rgb(245, 245, 245) 100%);
  background: linear-gradient(to right, rgba(245, 245, 245, 0) 0%, rgb(245, 245, 245) 67%, rgb(245, 245, 245) 100%);
}
.header__submenu__rightarrow {
  display: block;
  right: 20px;
}
@media screen and (min-width: 85.5625em) {
  .header__submenu__rightarrow {
    display: none;
  }
}
@media screen and (max-width: 49.9375em) {
  .header__submenu__rightarrow {
    display: none;
  }
}
.header__submenu__leftarrow {
  display: none;
  left: 20px;
  transform: rotate(180deg);
}

@media screen and (min-width: 50em) and (max-width: 85.5625em) {
  .header__submenu__container {
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}

.header__submenu .header__submenu__container {
  display: flex;
}
@media screen and (max-width: 49.9375em) {
  .header__submenu .header__submenu__container {
    flex-direction: column;
  }
}

@media screen and (min-width: 85.625em) {
  .header__submenu .header-columns {
    column-count: 2;
  }
}

@media screen and (min-width: 85.625em) {
  .header__submenu .header__submenu__section {
    width: 100%;
  }
}

.header__submenu__section .header__submenu__headline {
  font-family: "RealText", Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: bold;
  color: #757364;
}
@media screen and (max-width: 49.9375em) {
  .header__submenu__section .header__submenu__headline {
    background-color: white;
  }
}
@media screen and (min-width: 85.625em) {
  .header__submenu__section .header__submenu__headline {
    margin-bottom: 1.625rem;
  }
}

.header__submenu__headline, .js-columns > li, .back {
  width: 100%;
  display: inline-block;
}
@media screen and (min-width: 85.625em) {
  .header__submenu__headline, .js-columns > li, .back {
    margin-bottom: 1rem;
  }
}
@media screen and (min-width: 50em) and (max-width: 85.5625em) {
  .header__submenu__headline, .js-columns > li, .back {
    width: initial;
    padding-right: 1.875rem;
  }
}
@media screen and (max-width: 49.9375em) {
  .header__submenu__headline, .js-columns > li, .back {
    padding-left: 4.33333vw;
    padding-right: 4.33333vw;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    border-bottom: 1px solid #D8D8D8;
  }
}

.header__submenu .back {
  cursor: pointer;
  font-family: "RealText", Helvetica, Arial, sans-serif;
  color: #0E84A0;
}
@media screen and (min-width: 48.0625em) {
  .header__submenu .back {
    display: none;
  }
}

.header__submenu ul li > a {
  font-family: "RealText", Helvetica, Arial, sans-serif;
  display: flex;
}
.header__submenu ul li > a::before {
  content: "→";
  color: #AAA792;
  margin-right: 0.375rem;
  display: none;
}
@media screen and (min-width: 85.625em) {
  .header__submenu ul li > a::before {
    display: inline;
  }
}
.header__submenu ul li > a::after {
  content: "→";
  color: #AAA792;
  margin-right: 0.375rem;
  display: none;
}
@media screen and (max-width: 49.9375em) {
  .header__submenu ul li > a::after {
    display: inline;
  }
}
@media screen and (max-width: 49.9375em) {
  .header__submenu ul li > a {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (min-width: 50em) and (max-width: 85.5625em) {
  .header__submenu__section > ul, .js-columns {
    display: flex;
  }
}

.header__burger {
  cursor: pointer;
}
.header__burger > span {
  transition: all 0.3s ease-in-out;
}
.header__burger.opened > span:nth-child(1) {
  width: 0%;
  left: 50%;
  opacity: 0;
  transform: translateY(5px);
}
.header__burger.opened > span:nth-child(2) {
  transform: translateY(5px) rotate(45deg);
}
.header__burger.opened > span:nth-child(3) {
  transform: translateY(5px) rotate(-45deg);
}
.header__burger.opened > span:nth-child(4) {
  width: 0%;
  left: 50%;
  opacity: 0;
  transform: translateY(-5px);
}

.as-h1 {
  font-size: 1.875rem;
  line-height: 1.4;
  margin-bottom: 2.5rem;
  font-family: "RealText", Helvetica, Arial, sans-serif;
  font-weight: 200;
  text-align: center;
}
@media screen and (min-width: 48em) {
  .as-h1 {
    font-size: 2.8125rem;
    line-height: 1.2;
  }
}

.section-stage--inverted p {
  font-size: 1rem;
  line-height: 1.5;
  color: #fff;
  text-shadow: rgba(0, 0, 0, 0.2) 0 0 10px;
}
@media screen and (min-width: 80em) {
  .section-stage--inverted p {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}

.section-stage--inverted .button:hover {
  background-color: #fff;
  color: #0E84A0;
}

.section-stage--inverted .button--primary:hover {
  background: transparent;
  color: #fff;
}

.play-video-link {
  display: block;
  margin-left: 10;
}
.play-video-link span {
  border-bottom: 1px solid #fff;
  padding-bottom: 2;
  color: #fff;
}
.play-video-link:hover {
  color: #fff;
}
.play-video-link:hover span {
  border-bottom: 0;
}
@media screen and (min-width: 48em) {
  .play-video-link {
    display: inline-block;
  }
}

.margin-left {
  margin-left: 10;
}

.play-video-link svg {
  margin-bottom: -2;
}

p.section-stage__inner__cta {
  font-size: 16;
}
@media screen and (min-width: 48em) {
  p.section-stage__inner__cta {
    font-size: 18;
  }
}

.section-intro {
  text-align: center;
}

.section-intro__inner {
  margin-left: auto;
  margin-right: auto;
}

.section-intro h1 {
  font-size: 1.25rem;
  line-height: 1.5;
  margin-bottom: em(10);
}
@media screen and (min-width: 48em) {
  .section-intro h1 {
    font-size: 1.875rem;
    line-height: 1.4;
  }
}

.section-intro p {
  font-size: 1rem;
  line-height: 1.5;
}
@media screen and (min-width: 80em) {
  .section-intro p {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}

.section-text h1::before {
  content: "";
  margin-bottom: 0.3125em;
  display: block;
  width: 1.125em;
  height: 0.125em;
  background: #D4D1B6;
}

.section-text h1 a {
  text-decoration: none;
}

.section-text__inner {
  width: 100%;
}
@media screen and (min-width: 63.9375em) {
  .section-text__inner {
    max-width: 50vw;
    margin-left: 8.3333333333vw;
  }
}
@media screen and (min-width: 63.9375em) and (min-width: 1920) {
  .section-text__inner {
    max-width: 960px;
  }
}
@media screen and (min-width: 63.9375em) and (min-width: 1920) {
  .section-text__inner {
    margin-left: 160px;
  }
}

.section-image {
  margin-top: 8.3333333333vw;
  margin-bottom: 8.3333333333vw;
}
@media screen and (min-width: 1920) {
  .section-image {
    margin-top: 160px;
  }
}
@media screen and (min-width: 1920) {
  .section-image {
    margin-bottom: 160px;
  }
}
@media screen and (min-width: 48em) {
  .section-image {
    margin-top: 2.0833333333vw;
    margin-bottom: 2.0833333333vw;
  }
}
@media screen and (min-width: 48em) and (min-width: 1920) {
  .section-image {
    margin-top: 40px;
  }
}
@media screen and (min-width: 48em) and (min-width: 1920) {
  .section-image {
    margin-bottom: 40px;
  }
}

.section-features {
  max-width: 100vw;
}
@media screen and (min-width: 1920) {
  .section-features {
    max-width: 1920px;
  }
}

.section-features__item {
  margin-bottom: 3.75em;
}

.section-features h2 {
  font-size: 1.625rem;
  margin-bottom: 0.625rem;
}
@media screen and (min-width: 80em) {
  .section-features h2 {
    font-size: 2rem;
  }
}

.section-features h4 {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 0.625em;
}
@media screen and (min-width: 36em) {
  .section-features h4 {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}
@media screen and (min-width: 48em) {
  .section-features h4 {
    font-size: 1.875rem;
    line-height: 1.4;
  }
}

.section-features p {
  font-size: 1rem;
  line-height: 1.5;
}
@media screen and (min-width: 80em) {
  .section-features p {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}

.section-features ul {
  font-size: 1rem;
  line-height: 1.5;
  font-family: "RealText", Helvetica, Arial, sans-serif;
  text-align: left;
}
@media screen and (min-width: 80em) {
  .section-features ul {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}

.section-features li  {
  position: relative;
  display: block;
  margin-bottom: 0.25rem;
  padding-left: 1.875rem;
}

.section-features li:before {
  content: "→";
  position: absolute;
  left: 30px;
  margin-left: -1.875rem;
  color: #D4D1B6;
}

.section-text-image {
  overflow: hidden;
}

.section-text-image h1::before {
  content: "";
  margin-bottom: 0.3125em;
  display: block;
  width: 1.125em;
  height: 0.125em;
  background: #D4D1B6;
}

.section-text-image__copy {
  width: 100%;
}
@media screen and (min-width: 48em) {
  .section-text-image__copy {
    width: 50vw;
    padding-left: 2.0833333333vw;
    float: left;
  }
}
@media screen and (min-width: 48em) and (min-width: 1920) {
  .section-text-image__copy {
    width: 960px;
  }
}
@media screen and (min-width: 48em) and (min-width: 1920) {
  .section-text-image__copy {
    padding-left: 40px;
  }
}

.section-text-image__image {
  width: 100%;
}
@media screen and (max-width: 48em) {
  .section-text-image__image {
    margin-bottom: 2.5em;
  }
}
@media screen and (min-width: 48em) {
  .section-text-image__image {
    width: 33.3333333333vw;
    padding: 2.0833333333vw;
    float: right;
    margin-top: 80px;
  }
}
@media screen and (min-width: 48em) and (min-width: 1920) {
  .section-text-image__image {
    width: 640px;
  }
}
@media screen and (min-width: 48em) and (min-width: 1920) {
  .section-text-image__image {
    padding: 40px;
  }
}

@media screen and (min-width: 48em) {
  .section-text-image--flipped .section-text-image__copy {
    width: 50vw;
    float: right;
  }
}
@media screen and (min-width: 48em) and (min-width: 1920) {
  .section-text-image--flipped .section-text-image__copy {
    width: 960px;
  }
}

@media screen and (min-width: 48em) {
  .section-text-image--flipped .section-text-image__image {
    width: 33.3333333333vw;
    float: left;
  }
}
@media screen and (min-width: 48em) and (min-width: 1920) {
  .section-text-image--flipped .section-text-image__image {
    width: 640px;
  }
}

.section-text-image__image figcaption {
  font-size: 0.75rem;
  line-height: 1.4;
  margin-top: 0.625em;
  font-family: "RealText", Helvetica, Arial, sans-serif;
  color: #aaa792;
}
@media screen and (min-width: 48em) {
  .section-text-image__image figcaption {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}

.section-cta {
  background: #0E84A0;
  text-align: center;
}

.section-cta h2 {
  color: #fff;
  margin-bottom: em(20);
}

.section-cta p {
  color: #fff;
}

.section-cta .button {
  border-color: #fff;
}

.section-cta__inner {
  margin-left: auto;
  margin-right: auto;
}

.uniform__potty {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

textarea,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}

input[type=radio] {
  -webkit-appearance: radio !important;
}

.section-form {
  width: 100%;
  background: #0E84A0;
}

.section-form__address {
  width: 100%;
  text-align: center;
}
.section-form__address .section-home__cta {
  margin-left: 0;
}

.section-form__address__inner {
  margin-top: 1.875rem;
}
.section-form__address__inner p {
  margin-bottom: 0.375rem;
}

.contact-form {
  margin-bottom: 8.3333333333vw;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
@media screen and (min-width: 1920) {
  .contact-form {
    margin-bottom: 160px;
  }
}
@media screen and (min-width: 80em) {
  .contact-form {
    max-width: 50vw;
    margin-bottom: 4.1666666667vw;
  }
}
@media screen and (min-width: 80em) and (min-width: 1920) {
  .contact-form {
    max-width: 960px;
  }
}
@media screen and (min-width: 80em) and (min-width: 1920) {
  .contact-form {
    margin-bottom: 80px;
  }
}

.contact-form__row {
  width: 100%;
  display: inline-block;
}

.contact-form__row__cell {
  width: 100%;
  display: inline-block;
  float: left;
  padding: 0.625em;
}
@media screen and (min-width: 48em) {
  .contact-form__row__cell {
    width: 50%;
  }
}

.contact-form__row__inner {
  padding: 0.625em;
}

.contact-form__label,
.contact-form__textfield,
.contact-form__textarea,
.contact-form__select {
  font-size: 0.75rem;
  line-height: 1.4;
  width: 100%;
  display: block;
}
@media screen and (min-width: 48em) {
  .contact-form__label,
  .contact-form__textfield,
  .contact-form__textarea,
  .contact-form__select {
    font-size: 1rem;
    line-height: 1.5;
  }
}

.contact-form__label {
  font-family: "RealText", Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  margin-bottom: 10px;
}

.contact-form__textfield,
.contact-form__textarea {
  border: 3px solid #fff;
  padding: 0.75rem;
  border-radius: 3px;
}

.contact-form__select {
  border: 3px solid #fff;
  padding: 0.75rem;
  border-radius: 3px;
  background: #fff url("../assets/images/icon-dropdown.svg");
  background-position: 96% center;
  background-repeat: no-repeat;
}

.contact-form__textfield:focus,
.contact-form__textarea:focus {
  border-color: #0b6a80;
}

.contact-form__textfield.erroneous,
.contact-form__textarea.erroneous {
  padding: 0.625rem; /* 10px */
  border: 3px solid #E6450D;
}

.contact-form__textarea {
  min-height: 157px;
}

.contact-form__submit {
  font-size: 0.75rem;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: none;
  font-family: "RealText", Helvetica, Arial, sans-serif;
  margin: 0.5em;
  border-color: #fff !important;
  cursor: pointer;
}
@media screen and (min-width: 48em) {
  .contact-form__submit {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}
.contact-form__submit:hover {
  color: #0E84A0 !important;
}

.contact-form__submit:hover {
  box-shadow: none;
}

.contact-form__message {
  margin-top: 1.25em;
  margin-left: 0.625em;
  margin-right: 0.625em;
  margin-bottom: 1.25em;
  padding: 0.75rem;
  background: #CAFFD3;
  color: #0b6a80;
  font-family: "RealText", Helvetica, Arial, sans-serif;
  border-radius: 3px;
}

.phone {
  font-size: 1rem;
  line-height: 1.5;
  color: #fff;
  text-align: center;
}
@media screen and (min-width: 36em) {
  .phone {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}
@media screen and (min-width: 48em) {
  .phone {
    font-size: 1.875rem;
    line-height: 1.4;
  }
}

.phone a {
  color: #fff;
}

.section-form__contact__trust {
  text-align: center;
  color: #fff;
}

.section-text-infobox {
  overflow: hidden;
}

.section-text-infobox__copy h1::before {
  content: "";
  margin-bottom: 0.3125em;
  display: block;
  width: 1.125em;
  height: 0.125em;
  background: #D4D1B6;
}

.section-text-infobox__copy {
  width: 100%;
  margin-bottom: 2.5em;
}
@media screen and (min-width: 63.9375em) {
  .section-text-infobox__copy {
    width: 41.6666666667vw;
    float: left;
  }
}
@media screen and (min-width: 63.9375em) and (min-width: 1920) {
  .section-text-infobox__copy {
    width: 800px;
  }
}

.section-text-infobox__box {
  width: 100%;
  margin-bottom: 2.5em;
  padding: 1.25em;
  background: #0E84A0;
}
@media screen and (min-width: 63.9375em) {
  .section-text-infobox__box {
    width: 33.3333333333vw;
    margin-left: 8.3333333333vw;
    float: right;
  }
}
@media screen and (min-width: 63.9375em) and (min-width: 1920) {
  .section-text-infobox__box {
    width: 640px;
  }
}
@media screen and (min-width: 63.9375em) and (min-width: 1920) {
  .section-text-infobox__box {
    margin-left: 160px;
  }
}

.section-text-infobox__box__inner {
  padding: 1em;
  box-shadow: inset 2px 2px 0 #3e9db3, inset -2px 2px 0 #3e9db3, inset 0 -2px 0 #3e9db3;
}

.section-text-infobox__box h1 {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 0.625em;
  color: #fff;
}
@media screen and (min-width: 80em) {
  .section-text-infobox__box h1 {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}

.section-text-infobox__box p {
  font-size: 0.75rem;
  line-height: 1.4;
  margin-bottom: 0;
  color: #fff;
}
@media screen and (min-width: 48em) {
  .section-text-infobox__box p {
    font-size: 1rem;
    line-height: 1.5;
  }
}

.section-profil {
  width: 100%;
}
@media screen and (min-width: 48em) {
  .section-profil {
    padding-top: 4.1666666667vw;
    width: 100%;
  }
}
@media screen and (min-width: 48em) and (min-width: 1920) {
  .section-profil {
    padding-top: 80px;
  }
}
@media screen and (min-width: 80em) {
  .section-profil {
    max-width: 83.3333333333vw;
    margin-left: 8.3333333333vw;
  }
}
@media screen and (min-width: 80em) and (min-width: 1920) {
  .section-profil {
    max-width: 1600px;
  }
}
@media screen and (min-width: 80em) and (min-width: 1920) {
  .section-profil {
    margin-left: 160px;
  }
}

.section-profil__item {
  position: relative;
  margin-bottom: 3.75em;
  padding-left: 2.5em;
  padding-right: 2.5em;
  text-align: left;
}

.section-profil__item__inner {
  position: absolute;
  left: 1.25em;
  bottom: -0.625em;
  padding-left: 0.625em;
  padding-right: 0.625em;
  background: #fff;
}

.section-profil h2 {
  font-size: 1rem;
  line-height: 1.5;
}
@media screen and (min-width: 80em) {
  .section-profil h2 {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}

.team-label {
  font-size: 0.75rem;
  line-height: 1.4;
  margin-bottom: 0;
  padding-top: 0.25em;
  color: #aaa792;
}
@media screen and (min-width: 48em) {
  .team-label {
    font-size: 1rem;
    line-height: 1.5;
  }
}

.section-gallery {
  width: 100%;
  height: auto;
}

.carousel-cell {
  width: 100%;
  height: auto;
}

.section-home__image {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20;
}
@media screen and (min-width: 36em) {
  .section-home__image {
    width: 60%;
  }
}
@media screen and (min-width: 48em) {
  .section-home__image {
    width: 25vw;
    float: left;
    display: inline-block;
    margin-left: 0;
  }
}
@media screen and (min-width: 48em) and (min-width: 1920) {
  .section-home__image {
    width: 480px;
  }
}
@media screen and (min-width: 63.9375em) {
  .section-home__image {
    margin-left: 4.1666666667vw;
  }
}
@media screen and (min-width: 63.9375em) and (min-width: 1920) {
  .section-home__image {
    margin-left: 80px;
  }
}
.section-home__image img {
  max-width: 380px;
}
@media screen and (min-width: 63.9375em) {
  .section-home__image img {
    float: right;
  }
}

@media screen and (min-width: 48em) {
  .section-home__copy {
    float: left;
    width: 50vw;
    margin-left: 8.3333333333vw;
    display: inline-block;
  }
}
@media screen and (min-width: 48em) and (min-width: 1920) {
  .section-home__copy {
    width: 960px;
  }
}
@media screen and (min-width: 48em) and (min-width: 1920) {
  .section-home__copy {
    margin-left: 160px;
  }
}
.section-home__copy h1 {
  margin-left: 0 !important;
  text-align: left;
}
@media screen and (min-width: 63.9375em) {
  .section-home__copy {
    margin-left: 4.1666666667vw;
    margin-bottom: 40;
  }
}
@media screen and (min-width: 63.9375em) and (min-width: 1920) {
  .section-home__copy {
    margin-left: 80px;
  }
}

@media screen and (min-width: 48em) {
  .section-home__cta {
    display: inline-block;
    width: 100%;
  }
}
@media screen and (min-width: 63.9375em) {
  .section-home__cta {
    width: 50vw;
    margin-left: 4.1666666667vw;
  }
}
@media screen and (min-width: 63.9375em) and (min-width: 1920) {
  .section-home__cta {
    width: 960px;
  }
}
@media screen and (min-width: 63.9375em) and (min-width: 1920) {
  .section-home__cta {
    margin-left: 80px;
  }
}

.cta-list {
  margin-top: 1.25rem;
  font-family: "RealText", Helvetica, Arial, sans-serif;
}

.cta-list__item {
  display: inline-block;
  width: 100%;
  position: relative;
  margin-right: 0.9375rem;
  margin-bottom: 0.625rem;
  padding: 0.625rem 1.25rem 0.625rem 5rem;
  border: 2px solid #D4D1B6;
  border-radius: 6px;
  font-size: 0.875rem;
  line-height: 1.4;
  text-align: left;
}
@media screen and (min-width: 36em) {
  .cta-list__item {
    width: auto;
  }
}
.cta-list__item:last-child {
  margin-right: 0;
}
@media screen and (min-width: 63.9375em) {
  .cta-list__item {
    font-size: 1rem;
    padding: 1.25rem 1.25rem 1.25rem 5rem;
  }
}

.cta-list__item .icon {
  position: absolute;
  top: 0.5rem;
  left: 1.25rem;
  width: 40px;
  text-align: center;
}
@media screen and (min-width: 63.9375em) {
  .cta-list__item .icon {
    top: 1.125rem;
  }
}
.cta-list__item .icon svg {
  width: 80%;
}
@media screen and (min-width: 63.9375em) {
  .cta-list__item .icon svg {
    width: 100%;
  }
}

.section-home__cta--footer .cta-list__item {
  background: #fff;
  border: none;
  box-shadow: #0b6a80 0 3px 0;
}

.section-check-container {
  max-width: 100vw;
  width: 100%;
  padding-top: 0;
}
@media screen and (min-width: 1920) {
  .section-check-container {
    max-width: 1920px;
  }
}
@media screen and (min-width: 48em) {
  .section-check-container {
    display: inline-block;
  }
}
@media screen and (min-width: 80em) {
  .section-check-container {
    display: flex;
    align-items: flex-end;
  }
}

.section-check h2 {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: em(10px, 16px);
  text-align: center;
}
@media screen and (min-width: 36em) {
  .section-check h2 {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}
@media screen and (min-width: 48em) {
  .section-check h2 {
    font-size: 1.875rem;
    line-height: 1.4;
  }
}

.section-check p {
  font-size: 0.75rem;
  line-height: 1.4;
  margin-bottom: 1.875rem;
  text-align: left;
}
@media screen and (min-width: 48em) {
  .section-check p {
    font-size: 1rem;
    line-height: 1.5;
  }
}

@media screen and (min-width: 48em) {
  .section-check__item {
    float: left;
    width: 50%;
    margin-bottom: 3.75rem;
  }
}
@media screen and (min-width: 80em) {
  .section-check__item {
    width: 33.33%;
  }
}

.section-check__item__inner {
  padding: 1.25rem 1.25rem 0.625rem;
}
@media screen and (min-width: 48em) {
  .section-check__item__inner {
    padding: 2.5rem 2.5rem 1.25rem;
  }
}

.section-check__item__list {
  font-size: 0.75rem;
  line-height: 1.4;
  font-family: "RealText", Helvetica, Arial, sans-serif;
  text-align: left;
}
@media screen and (min-width: 48em) {
  .section-check__item__list {
    font-size: 1rem;
    line-height: 1.5;
  }
}

.section-check__item__list li {
  position: relative;
  margin-left: 1.5rem;
  margin-bottom: 0.5rem;
}
.section-check__item__list li:before {
  content: "";
  top: -0.125rem;
  left: -1.5rem;
  position: absolute;
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url("../assets/images/icon-check.svg") center no-repeat;
}
@media screen and (min-width: 48em) {
  .section-check__item__list li:before {
    top: 0.125rem;
  }
}

.section-check__item--featured {
  position: relative;
  background: #f6f6f0;
  border: 2px solid #eae8db;
  border-radius: 6px;
  z-index: 9999;
}
.section-check__item--featured .section-check__item__price {
  border-top: 2px solid #e5e3d3;
  border-bottom: 2px solid #e5e3d3;
}

.section-check img {
  display: block;
  max-width: 100px;
  margin-bottom: 1.25rem;
  margin-left: auto;
  margin-right: auto;
}

p.section-check__item__price {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 0.625rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  font-weight: 700;
  text-align: center;
  background: #fff;
}
@media screen and (min-width: 36em) {
  p.section-check__item__price {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}
@media screen and (min-width: 48em) {
  p.section-check__item__price {
    font-size: 1.875rem;
    line-height: 1.4;
  }
}

p.center {
  text-align: center;
}

.check-info {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.check-info p {
  font-size: 0.75rem;
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #aaa792;
}

.section-check-form .contact-form__textfield,
.section-check-form .contact-form__textarea,
.section-check-form .contact-form__select {
  border-color: #d8d8d8;
}
.section-check-form .contact-form__label {
  color: #999;
}
.section-check-form .contact-form__submit {
  cursor: pointer;
  border-color: #0E84A0 !important;
}
.section-check-form .contact-form__submit:hover {
  color: #0E84A0 !important;
  border-color: #0E84A0 !important;
}

.logo-list {
  width: 100%;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.logo-list__item {
  filter: grayscale(100%);
  transform: scale(0.8);
  display: inline-block;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  margin-bottom: 1.25rem;
  vertical-align: middle;
  opacity: 0.5;
}
@media screen and (min-width: 48em) {
  .logo-list__item {
    transform: scale(1);
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    margin-bottom: 2.5rem;
  }
}

.logo-list__item--mopo img {
  max-width: 280px;
}

.logo-list__item--zeitung img {
  max-width: 280px;
}

.logo-list__item--taspi img {
  max-width: 280px;
}

.logo-list__item--berwo img {
  max-width: 230px;
}

.logo-list__item--top img {
  max-width: 240px;
}

.logo-list__item--klassik img {
  max-width: 149px;
}

.section-quote h1 {
  margin-bottom: 3.75rem;
}

.section-quote__inner {
  margin-left: 8.3333333333vw;
  display: inline-block;
}
@media screen and (min-width: 1920) {
  .section-quote__inner {
    margin-left: 160px;
  }
}
.section-quote__inner blockquote {
  margin-bottom: em(30);
}
@media screen and (min-width: 48em) {
  .section-quote__inner blockquote {
    width: 50vw;
    margin-left: 4.1666666667vw;
    float: left;
    padding-left: 1.25em;
    border-left: 3px solid #e5e3d3;
  }
}
@media screen and (min-width: 48em) and (min-width: 1920) {
  .section-quote__inner blockquote {
    width: 960px;
  }
}
@media screen and (min-width: 48em) and (min-width: 1920) {
  .section-quote__inner blockquote {
    margin-left: 80px;
  }
}
.section-quote__inner blockquote p {
  font-size: 1.25rem;
  line-height: 1.5;
  margin-bottom: 0.625rem;
  font-weight: 200;
  color: #aaa792;
}
@media screen and (min-width: 48em) {
  .section-quote__inner blockquote p {
    font-size: 1.875rem;
    line-height: 1.4;
  }
}
.section-quote__inner blockquote cite {
  font-size: 0.75rem;
  line-height: 1.4;
  font-family: "RealText", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #7f7d6d;
  line-height: 1.4;
}
@media screen and (min-width: 48em) {
  .section-quote__inner blockquote cite {
    font-size: 1rem;
    line-height: 1.5;
  }
}

.section-quote__inner__image {
  display: block;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.5rem;
}
.section-quote__inner__image img {
  border-radius: 50%;
}
@media screen and (min-width: 48em) {
  .section-quote__inner__image {
    width: 16.6666666667vw;
    float: left;
    max-width: 280px;
    display: inline-block;
  }
}
@media screen and (min-width: 48em) and (min-width: 1920) {
  .section-quote__inner__image {
    width: 320px;
  }
}

.section-seo-text p {
  font-size: 12;
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #aaa792;
}
.section-seo-text p strong {
  font-weight: bold;
}

.section-embedded {
  text-align: center;
}
.section-embedded iframe {
  width: 80%;
  min-height: 400px;
}

.footer {
  padding-left: 8.3333333333vw;
  padding-right: 8.3333333333vw;
  padding-top: 4.1666666667vw;
  padding-bottom: 4.1666666667vw;
}
@media screen and (min-width: 1920) {
  .footer {
    padding-left: 160px;
  }
}
@media screen and (min-width: 1920) {
  .footer {
    padding-right: 160px;
  }
}
@media screen and (min-width: 1920) {
  .footer {
    padding-top: 80px;
  }
}
@media screen and (min-width: 1920) {
  .footer {
    padding-bottom: 80px;
  }
}

.footer p {
  font-size: 14;
}

.footer .copyright {
  display: inline-block;
}

.treatments {
  margin-bottom: 8.3333333333vw;
}
@media screen and (min-width: 1920) {
  .treatments {
    margin-bottom: 160px;
  }
}

@media screen and (min-width: 48em) {
  .treatments__item {
    padding-bottom: 4.1666666667vw;
    padding-top: 4.1666666667vw;
  }
}
@media screen and (min-width: 48em) and (min-width: 1920) {
  .treatments__item {
    padding-bottom: 80px;
  }
}
@media screen and (min-width: 48em) and (min-width: 1920) {
  .treatments__item {
    padding-top: 80px;
  }
}

.main.menu-opened {
  overflow: initial;
}

.open-sidebar {
  right: 8.3333333333vw;
  position: absolute;
  margin-top: 10;
}
@media screen and (min-width: 1920) {
  .open-sidebar {
    right: 160px;
  }
}
.open-sidebar a {
  display: block;
  padding-top: em(6);
  padding-bottom: em(6);
  font-size: 20;
  font-family: "RealText", Helvetica, Arial, sans-serif;
  color: #fff;
  transition: transform 0.33s cubic-bezier(0.33, 0, 0.2, 1);
}

.nav.is-left {
  transform: translateX(-320px);
}

.close-sidebar {
  float: right;
  margin-top: 10;
  padding: 16;
}

.sidebar {
  position: fixed;
  width: 320px;
  height: 100vh;
  top: 0;
  right: 0;
  background: #f6f6f6;
  z-index: 8888;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  transform: translateX(320px);
  transition: transform 0.33s cubic-bezier(0.33, 0, 0.2, 1);
  overflow: scroll;
}

.sidebar.is-open {
  display: block;
  transform: translateX(0);
}

.sidebar__nav {
  width: 100%;
  margin-top: 72;
}

.sidebar__nav ul {
  width: 100%;
  font-family: "RealText", Helvetica, Arial, sans-serif;
  border-top: 1px solid #ddd;
}

.sidebar__nav__list-item a {
  display: block;
  padding: 16;
  border-bottom: 1px solid #ddd;
  transition: ease-in-out all 0.2s;
}
.sidebar__nav__list-item a:hover, .sidebar__nav__list-item a.active {
  background: #fff;
}

.sidebar__main-cta .button {
  font-size: 16;
}

.sidebar__info-box {
  margin-top: 24;
  padding: 16;
}

.sidebar__info-box strong {
  color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.sidebar__info-box p {
  margin-bottom: 6;
  font-weight: 300;
}

.sidebar__info-box .social {
  float: none;
}

.logo-container__blur {
  animation-name: fade, jitter;
}

@keyframes fade {
  0% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.4;
  }
}
@keyframes jitter {
  0% {
    transform: translateX(-1px) scaleX(1.02);
  }
  25% {
    transform: translateX(3px) scaleX(1.04);
  }
  50% {
    transform: translateX(-3px) scaleX(1.02);
  }
  75% {
    transform: translateX(3px) scaleX(1);
  }
  100% {
    transform: translatex(-1px) scaleX(1.02);
  }
}
a.button:hover,
.button:hover {
  background: #fff;
  color: #0E84A0;
}

a.button--secondary,
.button--secondary {
  background: #fff;
  color: #0E84A0;
  border: 2px solid #0E84A0;
}
a.button--secondary:hover,
.button--secondary:hover {
  background: #0E84A0;
  color: #fff;
}

a.button--small:hover,
.button--small:hover {
  background: transparent;
  color: #fff;
  border-color: #fff;
}

section.book-button.mobile {
  text-align: center;
}
@media screen and (min-width: 800px) {
  section.book-button.mobile {
    display: none;
  }
}

a.button--primary__signal {
  display: inline-block;
  margin: 0 auto;
  background: #fc0;
  color: #332900;
  border-radius: 3px;
  border: 2px solid #fc0;
}
a.button--primary__signal:hover {
  background: #fc0;
  color: #332900;
  border: 2px solid #fc0;
}

.icon {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.625em;
  max-width: 6.25em;
}

.body-text {
  margin-bottom: 0em;
  max-width: 750px;
}
.body-text p,
.body-text h3, .body-text h4, .body-text h5, .body-text h6,
.body-text ul, .body-text ol, .body-text address {
  font-size: 1rem;
  line-height: 1.5;
  font-family: "RealText", Helvetica, Arial, sans-serif;
  font-weight: 300;
}
@media screen and (min-width: 80em) {
  .body-text p,
  .body-text h3, .body-text h4, .body-text h5, .body-text h6,
  .body-text ul, .body-text ol, .body-text address {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}
.body-text p, .body-text address {
  position: relative;
  margin-bottom: 1em;
}
.body-text p strong, .body-text p b, .body-text address strong, .body-text address b {
  font-weight: 500;
}
.body-text p em, .body-text p i, .body-text address em, .body-text address i {
  font-style: italic;
}
.body-text p:last-child, .body-text address:last-child {
  margin-bottom: 0;
}
.body-text h1, .body-text h2, .body-text h3, .body-text h4, .body-text h5, .body-text h6 {
  margin: 0 auto 0.5em auto;
}
.body-text h1:first-child, .body-text h2:first-child, .body-text h3:first-child, .body-text h4:first-child, .body-text h5:first-child, .body-text h6:first-child {
  margin-top: 0;
}
.body-text h3 {
  margin-top: 2em;
  color: #0E84A0;
}
.body-text h3 + h2 {
  margin-top: -0.2em;
}
.body-text p:last-child {
  margin-bottom: 0;
}
.body-text p + h2 {
  margin-top: 1em;
}
.body-text h1 {
  font-size: 1.875rem;
  line-height: 1.4;
  margin-bottom: 0.6em;
  margin-top: 0;
}
@media screen and (min-width: 48em) {
  .body-text h1 {
    font-size: 2.8125rem;
    line-height: 1.2;
  }
}
@media screen and (min-width: 80em) {
  .body-text h1 {
    font-size: 3.125rem;
    line-height: 1.2;
  }
}
@media screen and (min-width: 63.9375em) {
  .body-text h1 {
    margin-left: -2.0833333333vw;
  }
}
@media screen and (min-width: 63.9375em) and (min-width: 1920) {
  .body-text h1 {
    margin-left: -40px;
  }
}
.body-text h2 {
  font-size: 1.25rem;
  line-height: 1.5;
  font-weight: 200;
  margin-bottom: 0.7em;
  margin-top: -0.2em;
}
@media screen and (min-width: 48em) {
  .body-text h2 {
    font-size: 1.875rem;
    line-height: 1.4;
  }
}
.body-text h2.has-small-margin {
  margin-bottom: 0.7em;
}
.body-text h2.has-top-margin {
  margin-top: 1em;
  line-height: 1.6;
  margin-bottom: 0;
}
.body-text h4 {
  font-size: 1.25rem;
  line-height: 1.5;
}
@media screen and (min-width: 48em) {
  .body-text h4 {
    font-size: 1.875rem;
    line-height: 1.4;
  }
}
.body-text .as-journal-head {
  color: #aaa792;
  font-family: "RealText", Helvetica, Arial, sans-serif;
  font-weight: 300;
}
.body-text strong {
  font-weight: bold !important;
}
.body-text ul, .body-text ol {
  margin: 1em auto 1em auto;
}
.body-text ol li {
  margin-bottom: 0.5em;
  margin-left: 3em;
}
.body-text ul li {
  position: relative;
  padding-left: 1.2em;
  margin-bottom: 0.25em;
}
.body-text ul li:before {
  content: "•";
  position: absolute;
  left: 0;
  top: 0;
}
.body-text p a,
.body-text li a {
  display: inline;
  border-bottom: 1px solid #0E84A0;
}
.body-text p a:hover,
.body-text li a:hover {
  border-bottom: none;
}
.body-text img {
  margin-bottom: 1em;
}
.body-text .button {
  text-decoration: none;
}
.body-text blockquote {
  display: inline-block;
  padding-left: 1.25em;
  margin-top: 1.875em;
  margin-bottom: 1.875em;
  border-left: 3px solid #e5e3d3;
  font-size: 1.25rem;
  line-height: 1.5;
  color: #aaa792;
  font-family: "RealText", Helvetica, Arial, sans-serif;
  font-weight: 200;
}
@media screen and (min-width: 48em) {
  .body-text blockquote {
    font-size: 1.875rem;
    line-height: 1.4;
  }
}
.body-text blockquote p {
  font-size: 1.25rem;
  line-height: 1.5;
  color: #aaa792;
  font-weight: 200;
}
@media screen and (min-width: 48em) {
  .body-text blockquote p {
    font-size: 1.875rem;
    line-height: 1.4;
  }
}
.body-text blockquote cite {
  font-size: 0.75rem;
  line-height: 1.4;
  font-weight: 400;
  color: #7f7d6d;
}
@media screen and (min-width: 48em) {
  .body-text blockquote cite {
    font-size: 1rem;
    line-height: 1.5;
  }
}
.body-text time,
.body-text p.date {
  font-size: 0.75rem;
  line-height: 1.4;
  display: block;
  margin-bottom: 1.5em;
  font-family: "RealText", Helvetica, Arial, sans-serif;
  color: #aaa792;
}
@media screen and (min-width: 48em) {
  .body-text time,
  .body-text p.date {
    font-size: 1rem;
    line-height: 1.5;
  }
}
.body-text .as-headline {
  font-size: 1.875rem;
  line-height: 1.4;
  margin-bottom: 0.6em;
  margin-top: 0;
}
@media screen and (min-width: 48em) {
  .body-text .as-headline {
    font-size: 2.8125rem;
    line-height: 1.2;
  }
}
@media screen and (min-width: 80em) {
  .body-text .as-headline {
    font-size: 3.125rem;
    line-height: 1.2;
  }
}
@media screen and (min-width: 63.9375em) {
  .body-text .as-headline {
    margin-left: -2.0833333333vw;
  }
}
@media screen and (min-width: 63.9375em) and (min-width: 1920) {
  .body-text .as-headline {
    margin-left: -40px;
  }
}

.header-curve {
  transform: translateX(-5%);
  position: relative;
  width: 110%;
  margin-top: -5px;
}
.header-curve svg {
  width: 100%;
}

.subtitle {
  margin-left: 8.3333333333vw;
  font-size: 1.875rem;
  line-height: 1.4;
  font-family: "RealText", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #bfbca4;
}
@media screen and (min-width: 1920) {
  .subtitle {
    margin-left: 160px;
  }
}
@media screen and (min-width: 48em) {
  .subtitle {
    font-size: 2.8125rem;
    line-height: 1.2;
  }
}
@media screen and (min-width: 48em) {
  .subtitle {
    margin-left: 16.6666666667vw;
  }
}
@media screen and (min-width: 48em) and (min-width: 1920) {
  .subtitle {
    margin-left: 320px;
  }
}

.subtitle--form {
  margin-left: 0;
  margin-bottom: em(10);
  font-weight: 200;
  color: #fff;
  text-align: center;
}

.next-read {
  max-width: 83.3333333333vw;
  margin-bottom: 12.5vw;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding: 2.5em;
  background: #0E84A0;
}
@media screen and (min-width: 1920) {
  .next-read {
    max-width: 1600px;
  }
}
@media screen and (min-width: 1920) {
  .next-read {
    margin-bottom: 240px;
  }
}
@media screen and (min-width: 63.9375em) {
  .next-read {
    max-width: 50vw;
    margin-left: 41.6666666667vw;
    margin-bottom: 8.3333333333vw;
  }
}
@media screen and (min-width: 63.9375em) and (min-width: 1920) {
  .next-read {
    max-width: 960px;
  }
}
@media screen and (min-width: 63.9375em) and (min-width: 1920) {
  .next-read {
    margin-left: 800px;
  }
}
@media screen and (min-width: 63.9375em) and (min-width: 1920) {
  .next-read {
    margin-bottom: 160px;
  }
}

.next-read h3 {
  font-size: 1.875rem;
  line-height: 1.4;
  margin-left: 0;
  color: #fff;
}
@media screen and (min-width: 48em) {
  .next-read h3 {
    font-size: 2.8125rem;
    line-height: 1.2;
  }
}
@media screen and (max-width: 36em) {
  .next-read h3 {
    font-size: 1.25em;
  }
}

.next-read p {
  margin-bottom: 0.625em !important;
  color: #fff;
}

.next-read .button {
  position: absolute;
  right: 2.5em;
  bottom: -1.25em;
  background: #fff;
  color: #0E84A0;
}
.next-read .button:hover {
  background: #0E84A0;
  color: #fff;
  border-color: #fff;
}

.related-therapist {
  max-width: 83.3333333333vw;
  margin-bottom: 12.5vw;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
@media screen and (min-width: 1920) {
  .related-therapist {
    max-width: 1600px;
  }
}
@media screen and (min-width: 1920) {
  .related-therapist {
    margin-bottom: 240px;
  }
}
@media screen and (min-width: 63.9375em) {
  .related-therapist {
    max-width: 50vw;
    margin-left: 41.6666666667vw;
    margin-bottom: 8.3333333333vw;
  }
}
@media screen and (min-width: 63.9375em) and (min-width: 1920) {
  .related-therapist {
    max-width: 960px;
  }
}
@media screen and (min-width: 63.9375em) and (min-width: 1920) {
  .related-therapist {
    margin-left: 800px;
  }
}
@media screen and (min-width: 63.9375em) and (min-width: 1920) {
  .related-therapist {
    margin-bottom: 160px;
  }
}

@media screen and (min-width: 48em) {
  .section-invititation {
    margin-left: 8.3333333333vw;
  }
}
@media screen and (min-width: 48em) and (min-width: 1920) {
  .section-invititation {
    margin-left: 160px;
  }
}

.form {
  margin-bottom: 8.3333333333vw;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
@media screen and (min-width: 1920) {
  .form {
    margin-bottom: 160px;
  }
}
@media screen and (min-width: 80em) {
  .form {
    max-width: 50vw;
    margin-bottom: 4.1666666667vw;
  }
}
@media screen and (min-width: 80em) and (min-width: 1920) {
  .form {
    max-width: 960px;
  }
}
@media screen and (min-width: 80em) and (min-width: 1920) {
  .form {
    margin-bottom: 80px;
  }
}

.form__row {
  width: 100%;
  display: inline-block;
}

.form__row__cell {
  width: 100%;
  display: inline-block;
  float: left;
  padding: 0.625em;
}
@media screen and (min-width: 48em) {
  .form__row__cell {
    width: 50%;
  }
}

.form__label,
.form__textfield,
.form__textarea {
  font-size: 0.75rem;
  line-height: 1.4;
  width: 100%;
  max-width: 420px;
  display: block;
}
@media screen and (min-width: 48em) {
  .form__label,
  .form__textfield,
  .form__textarea {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}

.form__label {
  font-family: "RealText", Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: #0E84A0;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  margin-bottom: 10px;
}

.form__textfield,
.form__textarea {
  border: 3px solid #D4D1B6;
  padding: 0.75rem; /* 12px */
  border-radius: 3px;
}

.form__textfield:focus,
.form__textarea:focus {
  border-color: #0b6a80;
}

.responsiveContainer {
  position: relative;
  padding-bottom: 56%;
  height: 0;
  overflow: hidden;
}

.responsiveContainer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-container {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  max-width: 100%;
  background: rgba(14, 132, 160, 0.9);
  z-index: 9999;
  display: none;
}

.video-container.is-visible {
  display: block;
}

.no-scroll {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.video-container__close {
  position: absolute;
  right: 1.25em;
  top: 1.25em;
  font-family: "RealText", Helvetica, Arial, sans-serif;
  color: #fff !important;
  z-index: 9999;
}

.video-container__embed iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-container__embed {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding-bottom: 56.25%;
  padding-top: 0px;
  height: 0;
  overflow: hidden;
}

.video-container__inner {
  width: 100%;
  max-width: 1400px;
  height: 100%;
  margin-left: 50%;
  padding: 1.25em;
  transform: translateX(-50%);
}
@media screen and (min-width: 48em) {
  .video-container__inner {
    padding: 6.25em;
  }
}

.social {
  display: block;
  padding-bottom: 40;
}
@media screen and (min-width: 48em) {
  .social {
    display: inline-block;
    float: right;
  }
}

.social a {
  font-family: "RealText", Helvetica, Arial, sans-serif;
  font-size: 14;
}

.social a::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  margin-right: 0.625em;
  background: url("./../assets/images/icons/facebook.svg");
}

.pagination {
  max-width: 100vw;
  padding-left: 4.1666666667vw;
  padding-right: 4.1666666667vw;
  padding-top: em(40);
  padding-bottom: em(40);
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 1920) {
  .pagination {
    max-width: 1920px;
  }
}
@media screen and (min-width: 1920) {
  .pagination {
    padding-left: 80px;
  }
}
@media screen and (min-width: 1920) {
  .pagination {
    padding-right: 80px;
  }
}

.pagination__inner {
  padding-left: em(20);
  padding-right: em(20);
  text-align: center;
}
@media screen and (min-width: 48em) {
  .pagination__inner {
    padding-left: em(40);
    padding-right: em(40);
  }
}

.pagination span,
.pagination a {
  display: inline-block;
  background: #fff;
  margin-left: em(4);
  margin-right: em(4);
  padding: em(10) em(16);
  border: 2px solid #0E84A0;
  font-family: "RealText", Helvetica, Arial, sans-serif;
  font-size: 14;
  text-align: center;
}
@media screen and (min-width: 48em) {
  .pagination span,
  .pagination a {
    font-size: 16;
  }
}

.pagination span {
  background: #0E84A0;
  color: #fff;
}

@media screen and (min-width: 1000) {
  .praxis-nav {
    border-bottom: 2px solid #d4d1b6;
    padding-bottom: 20;
  }
}

.praxis-nav ul {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 1000) {
  .praxis-nav ul {
    flex-direction: row;
  }
}

.praxis-nav ul li {
  cursor: pointer;
  font-family: "RealText", Helvetica, Arial, sans-serif;
  font-size: 18;
  color: #0E84A0;
}
.praxis-nav ul li:not(:last-child) {
  margin-right: 60;
}
.praxis-nav ul li:not(:last-child)::after {
  content: "";
  display: block;
  width: 30px;
  height: 2px;
  background-color: #d4d1b6;
  margin: 18 0;
}
@media screen and (min-width: 1000) {
  .praxis-nav ul li:not(:last-child)::after {
    display: none;
  }
}

.breadcrumbs {
  display: flex;
}
.breadcrumbs li {
  align-items: center;
  display: flex;
}
.breadcrumbs li:not(:last-child)::after {
  color: #D5D1B6;
  content: "→";
  font-family: RealText, Helvetica, Arial, sans-serif;
  margin: 0 7px 0 5px;
}
.breadcrumbs span {
  font-size: 1rem;
  font-family: RealText, Helvetica, Arial, sans-serif;
  font-weight: 300;
}

/*! responsive-nav.js 1.0.39 by @viljamis */
.nav-collapse ul {
  margin: 0;
  width: 100%;
  display: block;
  list-style: none;
}

.js .nav-collapse {
  clip: rect(0 0 0 0);
  max-height: 0;
  position: absolute;
  display: block;
  overflow: hidden;
  zoom: 1;
}

.nav-collapse.opened {
  max-height: 9999px;
}

.nav-toggle {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

@media screen and (min-width: 768px) {
  .js .nav-collapse {
    position: relative;
  }
  .js .nav-collapse.closed {
    max-height: none;
  }
  .nav-toggle {
    display: none;
  }
}
/*! Flickity v1.2.0
http://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50%;
  background: white;
  background: hsla(0, 0%, 100%, 0.75);
  cursor: pointer;
  /* vertically center */
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.flickity-prev-next-button:hover {
  background: white;
}

.flickity-prev-next-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #09F;
}

.flickity-prev-next-button:active {
  filter: alpha(opacity=60); /* IE8 */
  opacity: 0.6;
}

.flickity-prev-next-button.previous {
  left: 10px;
}

.flickity-prev-next-button.next {
  right: 10px;
}

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button:disabled {
  filter: alpha(opacity=30); /* IE8 */
  opacity: 0.3;
  cursor: auto;
}

.flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

.flickity-prev-next-button .arrow {
  fill: #333;
}

/* color & size if no SVG - IE8 and Android 2.3 */
.flickity-prev-next-button.no-svg {
  color: #333;
  font-size: 26px;
}

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  filter: alpha(opacity=25); /* IE8 */
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  filter: alpha(opacity=100); /* IE8 */
  opacity: 1;
}

.praxis-profile {
  padding: 0 2em 2em;
  max-width: 420px;
}

.disable-analytics {
  background: transparent;
  border: 1px solid #0e84a0;
  border-radius: 5px;
  color: #0e84a0;
  cursor: pointer;
  display: block;
  font-family: RealText, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 300;
  margin-top: 10px;
  padding: 10px;
}

.analytics-modal-container {
  background-color: rgba(0, 0, 0, 0.3);
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}
.analytics-modal-container .analytics-modal {
  background-color: white;
  left: 50%;
  padding: 40px 20px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
}
.analytics-modal-container .analytics-modal p {
  margin-bottom: 0;
}
.analytics-modal-container .analytics-modal svg {
  cursor: pointer;
  right: 10px;
  position: absolute;
  top: 10px;
}