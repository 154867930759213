@use "../node_modules/sass-rem" as rem;
.logo-list {
  width: 100%;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.logo-list__item {
  filter: grayscale(100%);
  transform: scale(.8);
  display: inline-block;
  padding-left: rem.convert(20px);
  padding-right: rem.convert(20px);
  margin-bottom: rem.convert(20px);
  vertical-align: middle;
  opacity: .5;

  @include bp(min, $bp-3) {
    transform: scale(1);
    padding-left: rem.convert(40px);
    padding-right: rem.convert(40px);
    margin-bottom: rem.convert(40px);
  }
}

.logo-list__item--mopo img {
  max-width: 280px;
}

.logo-list__item--zeitung img {
  max-width: 280px;
}

.logo-list__item--taspi img {
  max-width: 280px;
}

.logo-list__item--berwo img {
  max-width: 230px;
}

.logo-list__item--top img {
  max-width: 240px;
}

.logo-list__item--klassik img {
  max-width: 149px;
}
