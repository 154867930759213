.subnav {
  @include unit(max-width, 10);
  @include unit(margin-top, 0.5);
  @include unit(margin-bottom, 0.5);
  margin-left: auto;
  margin-right: auto;

  @include bp(min, $bp-3) {
    @include unit(margin-top, 0.25);
  }
}

.subnav__list {
  width: 100%
}

.subnav__list li {
  display: inline-block;
  margin-right: em(15);
  margin-bottom: em(10);
}

.subnav__list li:after {
  content: '→';
  margin-left: em(15);
  font-family: $font-stack;
  color: $color-secondary;
}

.subnav__list li:last-child:after {
  content: none;
}

.subnav__list a {
  @include type-s-to-m;
  font-family: $font-stack;
}

.subnav__list a.active {
  color: shade($color-secondary, 20%);
}

.subnav--main {

  .subnav__list li:after {
    content: '|';
  }


  .subnav__list li:last-child:after {
    content: none;
  }

}
