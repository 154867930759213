.disable-analytics {
  background: transparent;
  border: 1px solid #0e84a0;
  border-radius: 5px;
  color: #0e84a0;
  cursor: pointer;
  display: block;
  font-family: RealText,Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 300;
  margin-top: 10px;
  padding: 10px;
}

.analytics-modal-container {
  background-color: rgba(0, 0, 0, 0.3);
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;

  .analytics-modal {
    background-color: white;
    left: 50%;
    padding: 40px 20px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);

    p {
      margin-bottom: 0;
    }

    svg {
      cursor: pointer;
      right: 10px;
      position: absolute;
      top: 10px;
    }
  }
}
