@use "../node_modules/sass-em" as em;

.section-invititation {
  @include bp(min, $bp-3) {
    @include unit(margin-left, 1);
  }
}


.form {
	@include unit(margin-bottom, 1);
	width: 100%;
  margin-left: auto;
  margin-right: auto;
	overflow: hidden;

  @include bp(min, $bp-5) {
    @include unit(max-width, 6);
		@include unit(margin-bottom, 0.5);
  }
}

.form__row {
  width: 100%;
  display: inline-block;
}

.form__row__cell {
  width: 100%;
  display: inline-block;
  float: left;
  padding: em.convert(10px, 16px);

  @include bp(min, $bp-3) {
    width: 50%;
  }
}

.form__label,
.form__textfield,
.form__textarea {
  @include type-s-to-ml;
  width: 100%;
  max-width: 420px;
	display: block;
}

.form__label {
  font-family: $font-stack;
	font-weight: 500;
	color: $color-primary;
	text-transform: uppercase;
	letter-spacing: 0.01em;
	margin-bottom: 10px;
}

.form__textfield,
.form__textarea {
	border: 3px solid $color-secondary;
	padding: 0.75rem; /* 12px */
  border-radius: 3px;
}

.form__textfield:focus,
.form__textarea:focus {
	border-color: shade($color-primary, 20%);
}
