.section-intro {
  text-align: center;
}

.section-intro__inner {
  margin-left: auto;
  margin-right: auto;
}

.section-intro h1 {
  @include type-ml-to-xl;
  margin-bottom: em(10);
}

.section-intro p {
  @include type-m-to-ml;
}
