@use "../node_modules/sass-rem" as rem;
.section-stage--inverted p {
  @include type-m-to-ml;
  color: #fff;
  text-shadow: rgba(0,0,0,0.2) 0 0 10px;
}

.section-stage--inverted .button {
  &:hover {
    background-color: #fff;
    color: $color-primary;
  }
}

.section-stage--inverted .button--primary {
  &:hover {
    background: transparent;
    color: #fff;
  }
}

.play-video-link {
  display: block;
  margin-left: rem.convert(10);

  span {
    border-bottom: 1px solid #fff;
    padding-bottom: rem.convert(2);
    color: #fff;
  }

  &:hover {
    color: #fff;

    span {
      border-bottom: 0;
    }
  }

  @include bp(min, $bp-3) {
    display: inline-block;
  }

}

.margin-left {
  margin-left: rem.convert(10);
}

.play-video-link svg {
  margin-bottom: rem.convert(-2);
}

p.section-stage__inner__cta {
  font-size: rem.convert(16);

  @include bp(min, $bp-3) {
    font-size: rem.convert(18);
  }
}
