@use "../node_modules/sass-rem" as rem;
@use "../node_modules/sass-em" as em;
.section-text-infobox {
  overflow: hidden;
}

.section-text-infobox__copy h1::before {
  content: '';
  margin-bottom: em.convert(5px, 16px);
  display: block;
  width: em.convert(18px, 16px);
  height: em.convert(2px, 16px);
  background: $color-secondary;
}

.section-text-infobox__copy {
  width: 100%;
  margin-bottom: em.convert(40px, 16px);

  @include bp(min, $bp-4) {
    @include unit(width, 5);
    float: left;
  }
}

.section-text-infobox__box {
  width: 100%;
  margin-bottom: em.convert(40px, 16px);
  padding: em.convert(20px, 16px);
  background: $color-primary;

  @include bp(min, $bp-4) {
    @include unit(width, 4);
    @include unit(margin-left);
    float: right;
  }
}

.section-text-infobox__box__inner {
  padding: em.convert(16px, 16px);
  box-shadow: inset 2px 2px 0 tint($color-primary, 20%), inset -2px 2px 0 tint($color-primary, 20%), inset 0 -2px 0 tint($color-primary, 20%);
}

.section-text-infobox__box h1 {
  @include type-m-to-ml;
  margin-bottom: em.convert(10px, 16px);
  color: #fff;
}

.section-text-infobox__box p {
  @include type-s-to-m;
  margin-bottom: 0;
  color: #fff;
}
