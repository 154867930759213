// Colors

/* --- Main Colors --- */

$color-primary: #0E84A0;
$color-secondary: #D4D1B6;
$color-signal: #fc0;

/* --- Link --- */

$color-link: $color-primary;

/* --- Button --- */

$color-button-bg: $color-primary;
$color-button-text: #fff;
$color-button-border: $color-primary;
$color-button-hover: #fff;
$color-button-hover-text: #fff;

$color-button-secondary-bg: #fff;
$color-button-secondary-text: $color-primary;
$color-button-secondary-border: $color-primary;
$color-button-secondary-hover: $color-primary;
$color-button-secondary-hover-text: $color-primary;


/* --- Stage --- */

$color-stage-bg: $color-primary;
$color-stage-text: #fff;


// Typography

$font-stack-head: "RealHead", Helvetica, Arial, sans-serif;
$font-stack: "RealText", Helvetica, Arial, sans-serif;
$font-size-xs: 10px;
$font-size-s: 12px;
$font-size-m: 16px;
$font-size-ml: 20px;
$font-size-l: 30px;
$font-size-xl: 45px;
$font-size-xxl: 50px;
$font-size-xxxl: 80px;

$line-height-s: 1.4;
$line-height-m: 1.5;
$line-height-ml: 1.5;
$line-height-l: 1.4;
$line-height-xl: 1.2;
$line-height-xxl: 1.2;
$line-height-xxxl: 1.2;


// Spacing

$text-max-width-small: 460px;

// Units/Grid

$grid-column-count: 12;
$grid-unit: calc(100 / $grid-column-count * 1vw);
$grid-fluid-max-width: 1920;

$bp-0: 320px;
$bp-1: 420px;
$bp-2: 576px;
$bp-3: 768px;
$bp-4: 1023px;
$bp-5: 1280px;
$bp-6: 1440px;
$bp-8: 1920px;

$max-width--page: 1920;
