@use "../node_modules/sass-em" as em;

@mixin bp($prop, $break1, $break2: "") {
  @if $prop == min {
    @media screen and (min-width: em.convert($break1, 16px)) {
      @content;
    }
  }
  @else if $prop == max {
    @media screen and (max-width: em.convert($break1, 16px)) {
      @content;
    }
  }
  @else if $prop == min-max {
    @media screen and (min-width: em.convert($break1, 16px)) and (max-width: em.convert($break2, 16px)) {
      @content;
    }
  }
}

@mixin bp-h($prop, $break1, $break2: "") {
  @if $prop == min {
    @media screen and (min-height: em.convert($break1, 16px)) {
      @content;
    }
  }
  @else if $prop == max {
    @media screen and (max-height: em.convert($break1, 16px)) {
      @content;
    }
  }
  @else if $prop == min-max {
    @media screen and (min-height: em.convert($break1, 16px)) and (max-height: em.convert($break2, 16px)) {
      @content;
    }
  }
}

@mixin bp-min-w-h($min-width, $min-height: "") {
  @media screen and (min-width: em.convert($min-width, 16px)) and (min-height: em.convert($min-height, 16px)) {
    @content;
  }
}

@mixin unit($property, $factor: 1, $important: false) {
  @if $important {
    #{$property}: $grid-unit * $factor !important;
    @include bp(min, $grid-fluid-max-width) {
      #{$property}: $grid-fluid-max-width / $grid-column-count * $factor * 1px !important;
    }
  }
  @else {
    #{$property}: $grid-unit * $factor;
    @include bp(min, $grid-fluid-max-width) {
      #{$property}: calc($grid-fluid-max-width / $grid-column-count * $factor * 1px);
    }
  }
}
