@use "../node_modules/sass-rem" as rem;
@use "../node_modules/sass-em" as em;
.body-text {
  margin-bottom: 0em;
  max-width: 750px;
  p,
  h3, h4, h5, h6,
  ul, ol, address {
    @include type-m-to-ml;
    font-family: $font-stack;
    font-weight: 300;
  }
  p, address {
    position: relative;
    margin-bottom: 1em;
    strong, b {
      font-weight: 500;
    }
    em, i {
      font-style: italic;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  h1, h2, h3, h4, h5, h6 {
    margin: 0 auto 0.5em auto;
    &:first-child {
      margin-top: 0;
    }
  }
  h3 {
    margin-top: 2em;
    color: $color-primary;
  }
  h3 + h2 {
    margin-top: -0.2em;
  }
  p:last-child {
    margin-bottom: 0;
  }
  p + h2 {
    margin-top: 1em;
  }
  h1 {
    @include type-l-to-xxl;
    margin-bottom: 0.6em;
    margin-top: 0;

    @include bp(min, $bp-4) {
      @include unit(margin-left, -0.25);
    }
  }
  h2 {
    @include type-ml-to-l;
    font-weight: 200;
    margin-bottom: 0.7em;
    margin-top: -0.2em;
    &.has-small-margin {
      margin-bottom: 0.7em;
    }
    &.has-top-margin {
      margin-top: 1em;
      line-height: 1.6;
      margin-bottom: 0;
    }
  }
  h4 {
    @include type-ml-to-l;
  }
  .as-journal-head {
    color: shade($color-secondary, 20%);
    font-family: $font-stack;
    font-weight: 300;
  }
  strong {
    font-weight: bold !important;
  }
  ul, ol {
    margin: 1em auto 1em auto;
  }
  ol {
    li {
      margin-bottom: 0.5em;
      margin-left: 3em;
    }
  }
  ul {
    li {
      position: relative;
      padding-left: 1.2em;
      margin-bottom: 0.25em;
      &:before {
        content: "•";
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
  p a,
  li a {
    display: inline;
    border-bottom: 1px solid $color-primary;

    &:hover {
      border-bottom: none;
    }
  }
  img {
    margin-bottom: 1em;
  }
  .button {
    text-decoration: none;
  }
  blockquote {
    display: inline-block;
    padding-left: em.convert(20px, 16px);
    margin-top: em.convert(30px, 16px);
    margin-bottom: em.convert(30px, 16px);
    border-left: 3px solid tint($color-secondary, 40%);
    @include type-ml-to-l;
    color: shade($color-secondary, 20%);
    font-family: $font-stack;
    font-weight: 200;
  }
  blockquote p {
    @include type-ml-to-l;
    color: shade($color-secondary, 20%);
    font-weight: 200;
  }
  blockquote cite {
    @include type-s-to-m;
    font-weight: 400;
    color: shade($color-secondary, 40%);
  }
  time,
  p.date {
    @include type-s-to-m;
    display: block;
    margin-bottom: em.convert(24px, 16px);
    font-family: $font-stack;
    color: shade($color-secondary, 20%);
  }
  .as-headline {
    @include type-l-to-xxl;
    margin-bottom: 0.6em;
    margin-top: 0;

    @include bp(min, $bp-4) {
      @include unit(margin-left, -0.25);
    }
  }
}
