@use "../node_modules/sass-rem" as rem;
@use "../node_modules/sass-em" as em;
.section-features {
  @include unit(max-width, 12);
}

.section-features__item {
  margin-bottom: em.convert(60px, 16px);
}

.section-features h2 {
  font-size: rem.convert(26px);
  margin-bottom: rem.convert(10px);
  @include bp(min, 1280px) {
    font-size: rem.convert(32px);
  }
}

.section-features h4 {
  @include type-m-to-l;
  margin-bottom: em.convert(10px, 16px);
}

.section-features p {
  @include type-m-to-ml;
}

.section-features ul {
  @include type-m-to-ml;
  font-family: $font-stack;
  text-align: left;
}

.section-features li {
  position: relative;
  display: block;
  margin-bottom: rem.convert(4px);
  padding-left: rem.convert(30px);
}

.section-features li:before {
  content: '→';
  position: absolute;
  left: 30px;
  margin-left: rem.convert(-30px);
  color: $color-secondary;
}
