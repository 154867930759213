.section-cta {
  background: $color-primary;
  text-align: center;
}

.section-cta h2 {
  color: #fff;
  margin-bottom: em(20);
}

.section-cta p {
  color: #fff;
}

.section-cta .button {
  border-color: #fff;
}

.section-cta__inner {
  margin-left: auto;
  margin-right: auto;
}
