@use "../node_modules/sass-rem" as rem;
.footer {
  @include unit(padding-left);
  @include unit(padding-right);
  @include unit(padding-top, 0.5);
  @include unit(padding-bottom, 0.5);
}

.footer p {
  font-size: rem.convert(14);
}

.footer .copyright {
  display: inline-block;
}
