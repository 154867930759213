@use "../node_modules/sass-rem" as rem;
@use "../node_modules/sass-em" as em;
.section-text-image {
  overflow: hidden;
}

.section-text-image h1::before {
  content: '';
  margin-bottom: em.convert(5px, 16px);
  display: block;
  width: em.convert(18px, 16px);
  height: em.convert(2px, 16px);
  background: $color-secondary;
}

.section-text-image__copy {
  width: 100%;
  @include bp(min, $bp-3) {
    @include unit(width, 6);
    @include unit(padding-left, 0.25);
    float: left;
  }
}

.section-text-image__image {
  width: 100%;

  @include bp(max, $bp-3) {
    margin-bottom: em.convert(40px, 16px);
  }

  @include bp(min, $bp-3) {
    @include unit(width, 4);
    @include unit (padding, 0.25);
    float: right;
    margin-top: 80px;
  }
}

.section-text-image--flipped .section-text-image__copy {
  @include bp(min, $bp-3) {
    @include unit(width, 6);
    float: right;
  }
}

.section-text-image--flipped .section-text-image__image {
  @include bp(min, $bp-3) {
    @include unit(width, 4);
    float: left;
  }
}

.section-text-image__image figcaption {
  @include type-s-to-ml;
  margin-top: em.convert(10px, 16px);
  font-family: $font-stack;
  color: shade($color-secondary, 20%);
}
