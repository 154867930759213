.logo-container__blur {
  animation-name: fade, jitter;
}

@keyframes fade {
  0% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.4;
  }
}


@keyframes jitter {
  0% {
    transform: translateX(-1px) scaleX(1.02);
  }
  25% {
    transform: translateX(3px) scaleX(1.04);
  }
  50% {
    transform: translateX(-3px) scaleX(1.02);
  }
  75% {
    transform: translateX(3px) scaleX(1);
  }
  100% {
    transform: translatex(-1px) scaleX(1.02);
  }
}
