@use "../node_modules/sass-rem" as rem;
@use "../node_modules/sass-em" as em;
.section-text h1::before {
  content: '';
  margin-bottom: em.convert(5px, 16px);
  display: block;
  width: em.convert(18px, 16px);
  height: em.convert(2px, 16px);
  background: $color-secondary;
}

.section-text h1 a {
  text-decoration: none;
}

.section-text__inner {
  width: 100%;
  @include bp(min, $bp-4) {
    @include unit(max-width, 6);
    @include unit(margin-left, 1);
  }
}
