.related-therapist {
  @include unit(max-width, 10);
  @include unit(margin-bottom, 1.5);
  margin-left: auto;
  margin-right: auto;
  position: relative;

  @include bp(min, $bp-4) {
    @include unit(max-width, 6);
    @include unit(margin-left, 5);
    @include unit(margin-bottom, 1);
  }
}
