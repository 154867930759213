.section-check-form {
  .contact-form__textfield,
  .contact-form__textarea,
  .contact-form__select {
  	border-color: #d8d8d8;
  }

  .contact-form__label {
    color: #999;
  }

  .contact-form__submit {
  	cursor: pointer;
    border-color: $color-primary !important;

    &:hover {
      color: $color-primary !important;
      border-color: $color-primary !important;
    }
  }
}
