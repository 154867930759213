.treatments {
  @include unit(margin-bottom, 1);
}

.treatments__item {
  @include bp(min, $bp-3) {
    @include unit(padding-bottom, 0.5);
    @include unit(padding-top, 0.5);
  }
}
