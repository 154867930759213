@use "../node_modules/sass-rem" as rem;
.section-home__image {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: rem.convert(20);

  @include bp(min, $bp-2) {
    width: 60%;
  }

  @include bp(min, $bp-3) {
    @include unit(width, 3);
    float: left;
    display: inline-block;
    margin-left: 0;
  }

  @include bp(min, $bp-4) {
    @include unit(margin-left, .5);
  }

  img {
    max-width: 380px;
    //border-radius: 50%;

    @include bp(min, $bp-4) {
      float: right;
    }
  }
}

.section-home__copy {
  @include bp(min, $bp-3) {
    float: left;
    @include unit(width, 6);
    @include unit(margin-left, 1);
    display: inline-block;
  }

  h1 {
    margin-left: 0 !important;
    text-align: left;
  }

  @include bp(min, $bp-4) {
    @include unit(margin-left, .5);
    margin-bottom: rem.convert(40);
  }
}

.section-home__cta {
  @include bp(min, $bp-3) {
    display: inline-block;
    width: 100%;
  }

  @include bp(min, $bp-4) {
    @include unit(width, 6);
    @include unit(margin-left, .5);
  }
}

.cta-list {
  margin-top: rem.convert(20px);
  font-family: $font-stack;
}

.cta-list__item {
  display: inline-block;
  width: 100%;
  position: relative;
  margin-right: rem.convert(15px);
  margin-bottom: rem.convert(10px);
  padding: rem.convert(10px) rem.convert(20px) rem.convert(10px) rem.convert(80px);
  border: 2px solid $color-secondary;
  border-radius: 6px;
  font-size: rem.convert(14px);
  line-height: 1.4;
  text-align: left;

  @include bp(min, $bp-2) {
    width: auto;
  }

  &:last-child {
    margin-right: 0;
  }

  @include bp(min, $bp-4) {
    font-size: rem.convert(16px);
    padding: rem.convert(20px) rem.convert(20px) rem.convert(20px) rem.convert(80px);
  }
}

.cta-list__item .icon {
  position: absolute;
  top: rem.convert(8px);
  left: rem.convert(20px);
  width: 40px;
  text-align: center;

  @include bp(min, $bp-4) {
    top: rem.convert(18px);
  }

  svg {
    width: 80%;

    @include bp(min, $bp-4) {
      width: 100%;
    }
  }
}

.section-home__cta--footer {

  .cta-list__item {
    background: #fff;
    border: none;
    box-shadow: shade($color-primary, 20%) 0 3px 0;
  }

}
