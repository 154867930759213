// Potty is there to prevent spam mails, don’t touch this
@use "../node_modules/sass-rem" as rem;
@use "../node_modules/sass-em" as em;

.uniform__potty {
	position: absolute;
	top: -9999px;
	left: -9999px;
}

// Form Styles

textarea,
select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: none;
}

input[type="radio"] {
	-webkit-appearance: radio !important;
}

.section-form {
	width: 100%;
  background: $color-primary;
}

.section-form__address {
	width: 100%;
	text-align: center;

	.section-home__cta {
		margin-left: 0;
	}
}

.section-form__address__inner {
	margin-top: rem.convert(30px);

	p {
		margin-bottom: rem.convert(6px);
	}
}

.contact-form {
	@include unit(margin-bottom, 1);
	width: 100%;
  margin-left: auto;
  margin-right: auto;
	overflow: hidden;

  @include bp(min, $bp-5) {
    @include unit(max-width, 6);
		@include unit(margin-bottom, 0.5);
  }
}

.contact-form__row {
  width: 100%;
  display: inline-block;
}

.contact-form__row__cell {
  width: 100%;
  display: inline-block;
  float: left;
  padding: em.convert(10px, 16px);

  @include bp(min, $bp-3) {
    width: 50%;
  }
}

.contact-form__row__inner {
  padding: em.convert(10px, 16px);
}

.contact-form__label,
.contact-form__textfield,
.contact-form__textarea,
.contact-form__select {
  @include type-s-to-m;
  width: 100%;
	display: block;
}

.contact-form__label {
  font-family: $font-stack;
	font-weight: 500;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 0.01em;
	margin-bottom: 10px;
}

.contact-form__textfield,
.contact-form__textarea {
	border: 3px solid #fff;
	padding: 0.75rem;
  border-radius: 3px;
}

.contact-form__select {
	border: 3px solid #fff;
	padding: 0.75rem;
  border-radius: 3px;
	background: #fff url('../assets/images/icon-dropdown.svg');
	background-position: 96% center;
	background-repeat: no-repeat;
}

.contact-form__textfield:focus,
.contact-form__textarea:focus {
	border-color: shade($color-primary, 20%);
}

.contact-form__textfield.erroneous,
.contact-form__textarea.erroneous {
	padding: 0.625rem; /* 10px */
	border: 3px solid #E6450D;
}

.contact-form__textarea {
	min-height: 157px;
}

.contact-form__submit {
  @include type-s-to-ml;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	border: none;
	font-family: $font-stack;
  margin: em.convert(8px, 16px);
  border-color: #fff !important;
	cursor: pointer;

  &:hover {
    color: $color-primary !important;
  }
}

.contact-form__submit:hover {
  box-shadow: none;
}

.contact-form__message {
	margin-top: em.convert(20px, 16px);
	margin-left: em.convert(10px, 16px);
	margin-right: em.convert(10px, 16px);
	margin-bottom: em.convert(20px, 16px);
	padding: rem.convert(12px);
	background: #CAFFD3;
	color: shade($color-primary, 20%);
	font-family: $font-stack;
	border-radius: 3px;
}

.phone {
	@include type-m-to-l;
	color: #fff;
	text-align: center;
}

.phone a {
	color: #fff;
}

.section-form__contact__trust {
	text-align: center;
	color: #fff;
}
