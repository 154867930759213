@use "../node_modules/sass-rem" as rem;
// new sidebar nav

.main {
  &.menu-opened {
    overflow: initial;
  }
}

.open-sidebar {
  @include unit(right);
  position: absolute;
  margin-top: rem.convert(10);

  a {
    display: block;
    padding-top: em(6);
    padding-bottom: em(6);
    font-size: rem.convert(20);
    font-family: $font-stack;
    color: #fff;
    transition: transform 0.33s cubic-bezier(0.33, 0, 0.2, 1);
  }
}

.nav.is-left {
  transform: translateX(-320px);
}

.close-sidebar {
  float: right;
  margin-top: rem.convert(10);
  padding: rem.convert(16);
}

.sidebar {
  position: fixed;
  width: 320px;
  height: 100vh;
  top: 0;
  right: 0;
  background: #f6f6f6;
  z-index: 8888;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  transform: translateX(320px);
  transition: transform 0.33s cubic-bezier(0.33, 0, 0.2, 1);
  overflow: scroll;
}

.sidebar.is-open {
  display: block;
  transform: translateX(0);
}

.sidebar__nav {
  width: 100%;
  margin-top: rem.convert(72);
}

.sidebar__nav ul {
  width: 100%;
  font-family: $font-stack;
  border-top: 1px solid #ddd;
}

.sidebar__nav__list-item a {
  display: block;
  padding: rem.convert(16);
  border-bottom: 1px solid #ddd;
  transition: ease-in-out all 0.2s;

  &:hover, &.active {
    background: #fff;
  }
}

.sidebar__main-cta .button {
  font-size: rem.convert(16);
}

.sidebar__info-box {
  margin-top: rem.convert(24);
  padding: rem.convert(16);
}

.sidebar__info-box strong {
  color: rgba(0,0,0,0.5);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.sidebar__info-box p {
  margin-bottom: rem.convert(6);
  font-weight: 300;
}

.sidebar__info-box .social {
  float: none;
}
