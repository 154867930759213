@use "../node_modules/sass-rem" as rem;
.header {
  @include bp(min, 1370px) {
    @include unit(padding-left);
    @include unit(padding-right);
    height: 120px;
  }
}

.header--home {
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: $color-primary;
  }
}

.header__submenu {
  // display: none;
  position: absolute;
  top: 160px;
  left: 0;
  background: #F6F6F6;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.10);

  @include bp(min, 1370px) {
    @include unit(padding-left);
    @include unit(padding-right);
    padding-top: rem.convert(30px);
    padding-bottom: rem.convert(30px);
    top: 120px;
  }

  @include bp(min-max, 800px, 1369px) {
    padding-left: 4.33333vw;
    padding-right: 4.33333vw;
    height: 80px;
    line-height: 80px;
  }

  @include bp(max, 799px) {
    display: block!important;
    top: 80px;
    transition: transform 0.33s cubic-bezier(0.33, 0, 0.2, 1);

    &.slided {
      transform: translateX(0%);
    }
  }

  &__arrow {
    cursor: pointer;
    position: absolute;
    top: 0;
    display: none;
    width: 100px;
    height: 100%;
    text-align: right;
    color: #0e84a0;
    font-family: $font-stack;
    font-size: rem.convert(18px);
    font-weight: bold;
    background: rgba(245,245,245,0);
    background: -moz-linear-gradient(left, rgba(245,245,245,0) 0%, rgba(245,245,245,1) 67%, rgba(245,245,245,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(245,245,245,0)), color-stop(67%, rgba(245,245,245,1)), color-stop(100%, rgba(245,245,245,1)));
    background: -webkit-linear-gradient(left, rgba(245,245,245,0) 0%, rgba(245,245,245,1) 67%, rgba(245,245,245,1) 100%);
    background: -o-linear-gradient(left, rgba(245,245,245,0) 0%, rgba(245,245,245,1) 67%, rgba(245,245,245,1) 100%);
    background: -ms-linear-gradient(left, rgba(245,245,245,0) 0%, rgba(245,245,245,1) 67%, rgba(245,245,245,1) 100%);
    background: linear-gradient(to right, rgba(245,245,245,0) 0%, rgba(245,245,245,1) 67%, rgba(245,245,245,1) 100%);
  }

  &__rightarrow {
    display: block;
    right: 20px;
    @include bp(min, 1369px) {
      display: none;
    }
    @include bp(max, 799px) {
      display: none;
    }
  }

  &__leftarrow {
    display: none;
    left: 20px;
    transform: rotate(180deg); // Reverse the linear gradient for the other side
  }
}

.header__submenu__container {
  @include bp(min-max, 800px, 1369px) {
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}

.header__submenu .header__submenu__container {
  display: flex;

  @include bp(max, 799px) {
    flex-direction: column;
  }
}

.header__submenu .header-columns {
  @include bp(min, 1370px) {
    column-count: 2;
  }
}

.header__submenu .header__submenu__section {
  @include bp(min, 1370px) {
    width: 100%;
  }
}

.header__submenu__section .header__submenu__headline {
  font-family: $font-stack;
  font-size: rem.convert(18px);
  font-weight: bold;
  color: shade($color-secondary, 45%);

  @include bp(max, 799px) {
    background-color: white;
  }
  @include bp(min, 1370px) {
    margin-bottom: rem.convert(26px);
  }
}

.header__submenu__headline, .js-columns > li, .back {
  width: 100%;
  display: inline-block;
  @include bp(min, 1370px) {
    margin-bottom: rem.convert(16px);
  }

  @include bp(min-max, 800px, 1369px) {
    width: initial;
    padding-right: rem.convert(30px);
  }

  @include bp(max, 799px) {
    padding-left: 4.33333vw;
    padding-right: 4.33333vw;
    padding-top: rem.convert(20px);
    padding-bottom: rem.convert(20px);
    border-bottom: 1px solid #D8D8D8;
  }
}

.header__submenu .back {
  cursor: pointer;
  font-family: $font-stack;
  color: #0E84A0;

  @include bp(min, 769px) {
    display: none;
  }
}

.header__submenu ul li > a {
  font-family: $font-stack;
  display: flex;
  &::before {
    content: '→';
    color: #AAA792;
    margin-right: rem.convert(6px);
    display: none;
    @include bp(min, 1370px) {
      display: inline;
    }
  }

  &::after {
    content: '→';
    color: #AAA792;
    margin-right: rem.convert(6px);
    display: none;
    @include bp(max, 799px) {
      display: inline;
    }
  }

  @include bp(max, 799px) {
   display: flex;
   justify-content: space-between;
  }
}

.header__submenu__section > ul, .js-columns {
  @include bp(min-max, 800px, 1369px) {
    display: flex;
  }
}

.header__burger {
  cursor: pointer;

  > span {
    transition: all 0.3s ease-in-out;
  }

  &.opened {

    > span {
      &:nth-child(1) {
        width: 0%;
        left: 50%;
        opacity: 0;
        transform: translateY(5px);
      }

      &:nth-child(2) {
        transform: translateY(5px) rotate(45deg);
      }

      &:nth-child(3) {
        transform: translateY(5px) rotate(-45deg);
      }

      &:nth-child(4) {
        width: 0%;
        left: 50%;
        opacity: 0;
        transform: translateY(-5px);
      }
    }
  }
}
