@use "../node_modules/sass-rem" as rem;
.section-check-container {
  @include unit(max-width, 12);
  width: 100%;
  padding-top: 0;

  @include bp(min, $bp-3) {
    display: inline-block;
  }

  @include bp(min, $bp-5) {
    display: flex;
    align-items: flex-end;
  }
}

.section-check h2 {
  @include type-m-to-l;
  margin-bottom: em(10px, 16px);
  text-align: center;
}

.section-check p {
  @include type-s-to-m;
  margin-bottom: rem.convert(30px);
  text-align: left;
}

.section-check__item {
  @include bp(min, $bp-3) {
    float: left;
    width: 50%;
    margin-bottom: rem.convert(60px);
  }

  @include bp(min, $bp-5) {
    width: 33.33%;
  }
}

.section-check__item__inner {
  padding: rem.convert(20px) rem.convert(20px) rem.convert(10px);

  @include bp(min, $bp-3) {
    padding: rem.convert(40px) rem.convert(40px) rem.convert(20px);
  }
}

.section-check__item__list {
  @include type-s-to-m;
  font-family: $font-stack;
  text-align: left;
}

.section-check__item__list li {
  position: relative;
  margin-left: rem.convert(24px);
  margin-bottom: rem.convert(8px);

  &:before {
    content: '';
    top: rem.convert(-2px);
    left: rem.convert(-24px);
    position: absolute;
    display: inline-block;
    width: 18px;
    height: 18px;
    background: url('../assets/images/icon-check.svg') center no-repeat;

    @include bp(min, $bp-3) {
      top: rem.convert(2px);
    }
  }
}

.section-check__item--featured {
  position: relative;
  background: tint($color-secondary, 80%);
  border: 2px solid tint($color-secondary, 50%);
  border-radius: 6px;
  z-index: 9999;

  .section-check__item__price {
    border-top: 2px solid tint($color-secondary, 40%);
    border-bottom: 2px solid tint($color-secondary, 40%);
  }
}

.section-check img {
  display: block;
  max-width: 100px;
  margin-bottom: rem.convert(20px);
  margin-left: auto;
  margin-right: auto;
}

p.section-check__item__price {
  @include type-m-to-l;
  margin-bottom: rem.convert(10px);
  padding-top: rem.convert(10px);
  padding-bottom: rem.convert(10px);
  font-weight: 700;
  text-align: center;
  background: #fff;
}

p.center {
  text-align: center;
}

.check-info {
  padding-left: rem.convert(20px);
  padding-right: rem.convert(20px);

  p {
    font-size: rem.convert(12px);
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    color: shade($color-secondary, 20%);
  }
}
