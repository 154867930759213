.subtitle {
  @include unit(margin-left, 1);
  @include type-l-to-xl;
  font-family: $font-stack;
  font-weight: 400;
  color: shade($color-secondary, 10%);

  @include bp(min, $bp-3) {
    @include unit(margin-left, 2);
  }
}

.subtitle--form {
  margin-left: 0;
  margin-bottom: em(10);
  font-weight: 200;
  color: #fff;
  text-align: center;
}
