.breadcrumbs {
  display: flex;

  li {
    align-items: center;
    display: flex;
    &:not(:last-child) {
      &::after {
        color: #D5D1B6;
        content: '→';
        font-family: RealText,Helvetica,Arial,sans-serif;
        margin: 0 7px 0 5px;
      }
    }
  }
  span {
    font-size: 1rem;
    font-family: RealText,Helvetica,Arial,sans-serif;
    font-weight: 300;
  }
}
