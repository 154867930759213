.no-bottom-padding {
  padding-bottom: 0 !important;
}

.hide {
  display: none;
}

.response {
  @include type-m-to-ml;
  font-family: $font-stack;
  font-weight: 300;
}
