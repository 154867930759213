@use "../node_modules/sass-rem" as rem;
@use "../node_modules/sass-em" as em;

a:hover {
  color: shade($color-link, 50%);
}

h1,h2,h3,h4 {
  font-family: $font-stack-head;
  font-weight: 800;
  line-height: 1.2;
  color: $color-primary;
}

p {
  margin-bottom: em.convert(20px, 16px);
  font-family: $font-stack;
  line-height: 1.45;
}

a.anchor {
  display: none;
}

p.small {
  @include type-s-to-m;
  color: #fff;
  text-align: center;
}

.page.menu-opened  {
  overflow: initial;
}

.three-up {
  @include unit(margin-left, -0.5);
  @include unit(margin-right, -0.5);
  text-align: center;
  overflow: hidden;
  .cell {
    float: left;
    width: 100%;
    @include unit(padding-right, 0.5);
    @include unit(padding-left, 0.5);
    @include bp(min, $bp-1) {
      width: calc(100 / 2 * 1%);

      &:nth-child(2n+1) {
        clear: left;
      }
    }

    @include bp(min, $bp-4) {
      width: calc(100 / 3 * 1%);
      &:nth-child(2n+1) {
        clear: none;
      }
      &:nth-child(3n+1) {
        clear: left;
      }
    }
  }
}

.four-up {
  @include unit(margin-left, -0.5);
  @include unit(margin-right, -0.5);
  text-align: center;
  .cell {
    @include unit(margin-bottom, 1);
    @include unit(padding-right, 0.5);
    @include unit(padding-left, 0.5);
    display: inline-block;
    vertical-align: top;
    width: 50%;
    @include bp(min, $bp-4) {
      width: 25%;
    }
  }
}

.five-up {
  @include unit(margin-left, -0.5);
  @include unit(margin-right, -0.5);
  text-align: center;
  @include bp(min, $bp-4) {
    max-width: 1200px;
    margin: 0 auto !important;
  }

  .cell {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    @include unit(padding, 0.5);
    @include bp(min, $bp-2) {
      @include unit(padding, 0.25);
      width: 33%;
    }

    @include bp(min, $bp-4) {
      @include unit(padding, 0.15);
      width: 20%;
    }
  }
}

*.hidden {
  display: none;
}
*.cursor-pointer {
  cursor: pointer;
}
*.color-primary {
  color: $color-primary;
}
