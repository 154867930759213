@use "../node_modules/sass-rem" as rem;
.praxis-nav {
  @include bp(min, 1000) {
    border-bottom: 2px solid #d4d1b6;
    padding-bottom: rem.convert(20);
  }
}

.praxis-nav ul {
  display: flex;
  flex-direction: column;

  @include bp(min, 1000) {
    flex-direction: row;
  }
}

.praxis-nav ul li {
  cursor: pointer;
  font-family: $font-stack;
  font-size: rem.convert(18);
  color: $color-primary;
  &:not(:last-child) {
    margin-right: rem.convert(60);
    &::after {
      content: '';
      display: block;
      width: 30px;
      height: 2px;
      background-color: #d4d1b6;
      margin: rem.convert(18) 0;

      @include bp(min, 1000) {
        display: none;
      }
    }
  }
}


